import React, { useState, useContext } from 'react';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { CREATE_POLL, POLL_REACTIONS_SET } from '../../services/graphql';
import { Frown, ThumbsUp, ThumbsDown, Meh, Smile, Star, Download } from 'react-feather';
import { GET_CLASS_QUERY } from '../../../../services/class/graphql';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import loginImg from '../../../../images/book/login.png';
import ImageFadeIn from "react-image-fade-in";
import { AuthContext } from '../../../../services/auth/AuthProvider';
import PrintableStudentsList from '../../../Class/scenes/Students/components/PrintableStudentsList';

import styles from './Polls.module.scss';
import styles2 from '../../Project.module.scss';
import cx from 'classnames';

const CreatePoll = ({ classId, close, mathTopic }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [reaction, setReaction] = useState(null);
    const { currentUser } = useContext(AuthContext);


    const schema = Yup.object().shape({
        question: Yup.string().required('Please enter a question'),
        reactionType: Yup.string().required('Please select a reaction type')
    });

    const { data, loading, error } = useQuery(POLL_REACTIONS_SET)

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const [createPoll, { loading: creating }] = useMutation(CREATE_POLL, {
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId } }],
        onCompleted: ({ createPoll }) => {
            if (createPoll.errors && createPoll.errors.length !== 0) {
                setFormErrors(createPoll.errors);
                return;
            }
            if (createPoll?.poll?.id) {
                close();
                const projectId = createPoll.poll.mathProject.id
                navigate(`/project/${projectId}/poll/${createPoll.poll.id}`)
            }
        }
    });

    const onSubmit = values => {
        const { question, reactionType, allowComments } = values;
        createPoll({
            variables: {
                studentsClassId: classId,
                mathTopicId: mathTopic.id,
                pollReactionSetId: reactionType,
                question,
                allowComments
            }
        })
    }

    const renderIcons = reactionSet => {
        if (reactionSet === 'smileys') {
            return <> <Smile /><Meh /><Frown /> </>
        }
        if (reactionSet === 'stars') {
            return <><Star /><Star /><Star /></>
        }
        return <><ThumbsUp className='u-m-right-1' /> <ThumbsDown /></>
    }
    const [showPrintableList, setShowPrintableList] = useState(false);


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (showPrintableList) {
        return <PrintableStudentsList classId={classId} school={currentUser.school} exitPrintMode={() => setShowPrintableList(false)} />
    }

    if (data && data.pollReactionSets) {

        const { pollReactionSets } = data;
        return (

            <div className={styles.poll}>
                <div className={styles.pollContent}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Poll Question</p>
                        <div className='basic-form__group u-m-top-1'>
                            <textarea
                                className='basic-form__text-area'
                                ref={register}
                                name='question'
                                placeholder='Question'
                                rows={5}
                            ></textarea>
                        </div>
                        {errors.question && <p className='basic-form__hint'>{errors.question.message}</p>}

                        <div className='u-m-base-2'>
                            <p className='u-m-base-2'>Select reaction:</p>
                            <ul className={styles.reactionSelect}>
                                {pollReactionSets.map(rSet => (
                                    <li key={rSet.id} onClick={() => setReaction(rSet.id)} className={cx(styles.reactionSelectItem, { [styles.reactionSelectItemActive]: reaction === rSet.id })}>
                                        {renderIcons(rSet.reactionSet)}
                                    </li>
                                ))}
                            </ul>
                            <input type='hidden' defaultValue={reaction} ref={register} name="reactionType" />
                        </div>
                        {errors.reactionType && <p className='basic-form__hint'>{errors.reactionType.message}</p>}

                        <div className="basic-form__group basic-form__group--check u-m-base-0">
                            <input
                                name="allowComments"
                                className="basic-form__check-box"
                                ref={register}
                                type="checkbox"
                                id="allow"
                                defaultChecked={true}
                            />
                            <label className="basic-form__check-label" htmlFor="allow">Allow Pupil Comments</label>
                        </div>
                        <p className='u-m-base-2 u-m-left-3 small'>Pupils comments are only visible to you</p>


                        {formErrors && <FormErrors errors={formErrors} />}

                        <Button type='submit' disabled={creating} className='u-m-right-1'>{creating ? 'Saving...' : 'Create Poll'}</Button>
                        <Button outline type='button' onClick={close}>Cancel</Button>
                    </form>
                </div>
                <div className={styles.pollSidebar}>
                    <div className={cx(styles.pollSidebarPanel, 'u-m-base-3')}>
                        <Button disabled fullWidth>Start poll</Button>
                        <ul className={cx(styles2.resources, 'u-m-top-3')}>
                            <li>
                                <button className={cx('btn-reset', styles2.resourcesLink)} onClick={() => setShowPrintableList(true)}>
                                    <div className={styles2.resourcesImg}><ImageFadeIn src={loginImg} alt='parent letters' /></div>
                                    <p>Students Logins</p>
                                    <Download />
                                </button>
                            </li>
                        </ul>
                        <Button disabled fullWidth>Delete poll</Button>

                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default CreatePoll;