import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import { AlertTriangle, Edit3, FileText, XCircle, PlusCircle, Eye, CheckCircle } from 'react-feather';

import { FormErrors, Button } from '@axeedge/go-teacher-components';
import { CHAPTER_STATUS, ENTRY_STATUS } from '@axeedge/go-shared-utils';

import Output from '../../../../../../../../components/EditorJsOutput'
import GemButton from '../../../../../../../../components/GemButton';
import SaveConfirm from '../../../../../../../../components/SaveConfirm';

import ManageVersions from '../ManageVersions';
import VocabModule from '../../../VocabModule';

import { AuthContext } from '../../../../../../../../services/auth/AuthProvider';

import { GET_STUDENT_ENTRY, LEAVE_ENTRY_FEEDBACK_MUTATION } from '../../../../../../services/graphql';

import styles from './EntryModal.module.scss'

const EntryModal = ({ chapterStatus, entry, toggleEligible, closeModal, toggleVisibleInCasting, chapter }) => {

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const [formErrors, setFormErrors] = useState([]);
    const [newFeedback, setNewFeedback] = useState(entry.feedback || '');
    const [feedbackSaved, setFeedbackSaved] = useState(false);
    const [manageVersions, setManageVersions] = useState(false);
    const [restoreDone, setRestoreDone] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false)



    const schema = Yup.object().shape({
        feedback: Yup.string()
            .required('Please enter some text')
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema
    });

    useEffect(() => {
        setValue('feedback', newFeedback);
        if (feedbackSaved && newFeedback !== entry.feedback) {
            setFeedbackSaved(false);
        }
    }, [entry, feedbackSaved, newFeedback, setFeedbackSaved, setValue]);

    const client = useApolloClient();

    const [leaveFeedback, { loading: leavingFeedback }] = useMutation(LEAVE_ENTRY_FEEDBACK_MUTATION, {
        onCompleted: ({ feedbackForEntry }) => {
            if (feedbackForEntry.errors && feedbackForEntry.errors.length !== 0) {
                setFormErrors(feedbackForEntry.errors);
                return;
            } else {
                setFeedbackSaved(true);
                client.writeQuery({
                    query: GET_STUDENT_ENTRY,
                    variables: {
                        id: feedbackForEntry.studentEntry.id
                    },
                    data: {
                        studentEntry: feedbackForEntry.studentEntry
                    }
                })
            }
        }
    })

    const onSubmit = values => {
        const { feedback } = values;
        leaveFeedback({
            variables: {
                studentEntryId: entry.id,
                feedback,
                requiresRevision: true
            }
        });
    }

    const escapeVocab = (vocab) => vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const highlightVocab = (text, vocab) => {
        // https://stackoverflow.com/a/63849592
        let newText = text;
        const terms = vocab.map(v => escapeVocab(v.word));
        terms.sort((a, b) => b.length - a.length);
        let regEx = new RegExp(String.raw`\b(?:${terms.join('|')})\b`, 'gi');
        newText = newText.replace(regEx, (m) => `<span class='tagvocab'>${m}</span>`);
        return newText;
        // let newText = text;
        // vocab.map(v => {
        //     let exp = RegExp('\\b(' + escapeVocab(v.word) + ')\\b', 'i');
        //     newText = newText.replace(exp, `<span class=\"tagvocab\">${v.word}</span>`)
        // })
        // return newText;
    }

    const highlightEntry = (entryData) => {
        const vocab = entry.chapter.words;
        entryData.blocks.forEach(block => {
            if (block.data.text && block.data.text.length !== 0) {
                block.data.text = highlightVocab(block.data.text, vocab)
            }
        });
        return entryData
    }



    return (
        <div className={styles.entryModalBG}>
            {manageVersions ? (
                <ManageVersions entry={entry} setRestoreDone={setRestoreDone} close={() => setManageVersions(false)} />
            ) : (
                <Fragment>
                    <div className={styles.entryHeader}>
                        <h1 className="u-m-base-0">{entry.author.name}</h1>
                        <button onClick={() => closeModal()} className={styles.entryClose}>
                            <XCircle size={36}/>
                        </button>
                    </div>
                    {entry.entry &&
                        <div className="u-display-flex">
                            <span className={styles.chapStatus}>
                                {entry.entry ? entry.status === ENTRY_STATUS.submitted ? <><FileText size="18" className={styles.statusIcon} /> Entry has been submitted</> : entry.isActive && <><Edit3 size="16" className={styles.statusIcon} /> Pupil is writing </> : null}
                            </span>
                        </div>
                    }
                    <div className={styles.entryContainer}>
                        {
                            entry.entry ? (
                                <Fragment>
                                    <div className={styles.entryArea}>
                                        <p className={styles.subHeader}>{chapter.template.isSocial ? 'Social Emotional Question' : 'Essential Question'} {chapter.template.orderId} - Writing</p>
                                        <h1 className="u-m-base-4">{chapter.template.name}</h1>
                                        {entry.chapter.words?.length > 0 ?
                                            <Output data={highlightEntry(JSON.parse(entry.entry))} /> :
                                            <Output data={JSON.parse(entry.entry)} />
                                        } 
                                    </div>
                                    {entry.isActive && (
                                        <div className={styles.entrySidebar}>
                                            <div className="u-m-base-1">
                                                <span className={styles.loginCode}>School Code: <strong>{teacher.school.schoolCode }</strong> <span className={'u-m-left-2'}>Login Code: <strong>{entry.author.loginCode}</strong></span></span>
                                            </div>
                                            {!entry.isActive && <p className={styles.chapStatus}><AlertTriangle size="16" className={styles.statusIcon} />  Removed from this class</p>}
                                            {entry.entry && entry.isActive && chapterStatus === CHAPTER_STATUS.writing &&  <button className={styles.manageEntryBtn} onClick={() => setManageVersions(true)}><FileText size="16" className={styles.statusIcon} />Manage Versions</button>}
                                            {restoreDone && <SaveConfirm message='Version restored!' hideConfirm={() => setRestoreDone(false)} />}
                                            <GemButton studentId={entry.author.id} fullwidth />
                                            {chapterStatus <= CHAPTER_STATUS.writing_closed &&
                                                <>
                                                <div className={styles.entryAction}>
                                                    <Eye size="18" className={styles.entryActionIcon} stroke='#80B814' />
                                                    <p className={styles.entryActionLabel}>Shown in StarCasting</p>
                                                    <input disabled={entry.eligibleToWin} className={`switch ${entry.eligibleToWin && 'switchDisabled'}`} checked={!entry.hiddenToCasting} type='checkbox' id='switch' onChange={() => toggleVisibleInCasting()} value={!entry.hiddenToCasting} />
                                                    <label className={`switchLabel ${entry.eligibleToWin && 'switchLabelDisabled'}`} htmlFor='switch'>Shown In Start Casting</label>
                                                </div>
                                                {entry.chapter.starcastingEnabled && entry.entry && 
                                                    <div className={styles.entryAction}>
                                                        <CheckCircle size="18" className={styles.entryActionIcon} stroke='#80B814' />
                                                        <p className={styles.entryActionLabel}>Eligible to win</p>
                                                        <input className={`switch`} checked={entry.eligibleToWin} type='checkbox' id='switch2' onChange={() => toggleEligible()} value={entry.eligibleToWin} />
                                                        <label className='switchLabel' htmlFor='switch2'>Shown In Start Casting</label>
                                                    </div>
                                                }
                                                </>
                                            }
                                            {
                                                chapterStatus < CHAPTER_STATUS.voting ? (
                                                    <div className='u-m-top-2'>
                                                        {!showFeedback ? <button className={styles.manageEntryBtn} onClick={() => setShowFeedback(true)}><PlusCircle size="18" className={styles.statusIcon} />Add Feedback</button> 
                                                        :
                                                        <>
                                                        <h2>Your Feedback</h2>
                                                        {
                                                            entry.entry ? (
                                                                <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                                                                    <div className='basic-form__group'>
                                                                        <TextareaAutosize
                                                                            name='visibleFeedback'
                                                                            onChange={e => setNewFeedback(e.target.value)}
                                                                            value={newFeedback}
                                                                            placeholder={`Add some helpful feedback for ${entry.author.name}`}
                                                                            className='basic-form__text-box'
                                                                        />
                                                                        <input type='hidden' name='feedback' ref={register} defaultValue={newFeedback} />
                                                                    </div>
                                                                    {errors.feedback && <p className='basic-form__hint'>{errors.feedback.message}</p>}
                                                                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                                                    {feedbackSaved && <p className='u-m-base-1'>Your feedback has been saved!</p>}
                                                                    <Button disabled={newFeedback === entry.feedback} type='submit' fullwidth>{leavingFeedback ? 'Saving...' : 'Leave Feedback'}</Button>
                                                                </form>
                                                            ) : (
                                                                <p>Once {entry.author.name} has submitted their entry you can leave some useful feedback for them.</p>
                                                            )
                                                        }
                                                        </>
                                                        }
                                                    </div>
                                                ) : entry.feedback ? (
                                                    <div className='u-m-top-2'>
                                                        <h2>Your Feedback:</h2>
                                                        <p>"{entry.feedback}"</p>
                                                    </div>
                                                ) : (
                                                    <div className='u-m-top-2'>
                                                        <h2>Your Feedback:</h2>
                                                        <p>You didn't leave feedback for this entry.</p>
                                                    </div>
                                                )
                                            }
                                            {entry.chapter.words?.length > 0 && (
                                                <div className='u-m-top-2'>
                                                    <VocabModule entry={JSON.parse(entry.entry)} vocab={entry.chapter.words} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <div>
                                    <p className={styles.subHeader}>{chapter.template.isSocial ? 'Social Emotional Question' : 'Essential Question'} {chapter.template.orderId} - Writing</p>
                                    <h1 className="u-m-base-4">{chapter.template.name}</h1>
                                    <div className='icon-title'>
                                        <AlertTriangle />
                                        <p className='u-m-left-2'>No entry submitted {chapterStatus === CHAPTER_STATUS.writing && 'yet'}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {chapterStatus >= CHAPTER_STATUS.writing_closed && entry.entry && entry.status !== ENTRY_STATUS.submitted && <p className='u-m-top-2'><AlertTriangle className='u-m-top-1' size='14' /> Finished entry was not handed in before writing closed</p>}
                </Fragment>
            )}
        </div>
    )
}

export default EntryModal;
