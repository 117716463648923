import React from 'react';
import Topic from './Topic';
import { Folder } from 'react-feather';
import styles from './Courses.module.scss';

const Unit = ({ unit, curriculum, setViewTopic, classProjects }) => {

    const classHasTopicProject = (topic) => {
        return classProjects.find(project => project.topic.id === topic.id)
    }

    return (
        <>
            <div className={styles.unit}>
                <div className={styles.unitHeader}>
                    <div className={styles.unitHeaderIcon}>
                        <Folder />
                    </div>
                    <div>
                        <h3 className='u-m-base-1'>{unit.course.name} - Unit {unit.unitCode}</h3>
                        <p className='heavy u-m-base-0'>{unit.name}</p>
                    </div>
                </div>
                <div className={styles.unitTopics}>
                    {unit.topics.map(topic => (
                        <Topic
                            key={topic.id}
                            setViewTopic={setViewTopic}
                            hasProject={classHasTopicProject(topic)}
                            topic={topic}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default Unit