import React, { useState } from 'react';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { POLL_REACTIONS_SET, EDIT_POLL, POLL_QUERY } from '../../../services/graphql';

import { Frown, ThumbsUp, ThumbsDown, Meh, Smile, Star } from 'react-feather';
import * as Yup from 'yup';
import cx from 'classnames';
import styles from '../Polls.module.scss';

const PollSettings = ({ poll, isEdit, close }) => {

    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        question: Yup.string().required('Please enter a question'),
    });

    const { data, loading, error } = useQuery(POLL_REACTIONS_SET)

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });


    const [editPoll, { loading: editing }] = useMutation(EDIT_POLL, {
        onCompleted: ({ editPoll }) => {
            if (editPoll.errors && editPoll.errors.length !== 0) {
                setFormErrors(editPoll.errors);
                return;
            }
            if (editPoll?.poll?.id) {
                if (isEdit) {
                    close();
                }
            }
        },
        refetchQueries: [{ query: POLL_QUERY, variables: { id: poll.id } }]
    });

    const onSubmit = values => {
        const { question, allowComments } = values;
        editPoll({
            variables: {
                pollId: poll.id,
                mathProjectId: poll.mathProject.id,
                question,
                allowComments
            }
        })
    }

    const renderIcons = reactionSet => {
        if (reactionSet === 'smileys') {
            return <> <Smile /><Meh /><Frown /> </>
        }
        if (reactionSet === 'stars') {
            return <><Star /><Star /><Star /></>
        }
        return <><ThumbsUp className='u-m-right-1' /> <ThumbsDown /></>
    }

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.pollReactionSets) {

        const { pollReactionSets } = data;
        return (

            <form onSubmit={handleSubmit(onSubmit)}>
                <p>Poll Question</p>
                <div className='basic-form__group u-m-top-1'>
                    <textarea
                        className='basic-form__text-area'
                        ref={register}
                        name='question'
                        placeholder='Question'
                        rows={5}
                        defaultValue={poll.question}
                    ></textarea>
                </div>
                {errors.question && <p className='basic-form__hint'>{errors.question.message}</p>}

                <div className='u-m-base-2'>
                    <p className='u-m-base-1'>Poll Reaction:</p>
                    <ul className={cx(styles.reactionSelect, styles.reactionSelectLocked)}>
                        <li className={styles.reactionSelectItem}>
                            {renderIcons(pollReactionSets.find(el => el.id === poll.pollReactionSet.id).reactionSet)}
                        </li>
                    </ul>
                </div>

                <div className="basic-form__group basic-form__group--check u-m-base-0">
                    <input
                        name="allowComments"
                        className="basic-form__check-box"
                        ref={register}
                        type="checkbox"
                        id="allow"
                        defaultChecked={poll.allowComments}
                    />
                    <label className="basic-form__check-label" htmlFor="allow">Allow Pupil Comments</label>
                </div>
                <p className='u-m-base-2 u-m-left-3 small'>Pupils comments are only visible to you</p>
                {formErrors && <FormErrors errors={formErrors} />}
                <Button type='submit' disabled={editing} className='u-m-right-1'>{editing ? 'Saving...' : 'Save settings'}</Button>
                {isEdit && <Button type='button' onClick={() => close()} outline className='u-m-right-1'>Cancel</Button>}
            </form>
        )
    }
    return null;
}

export default PollSettings;