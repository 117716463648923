import React, { Fragment, useEffect } from 'react';
import _ from 'lodash';
import TemplateSubTitle from './TemplateSubTitle';
import StudentsEntriesList from './StudentEntriesList';

const WritingClosed = ({ chapter, setEnabled }) => {

    useEffect(() => {
        setEnabled(_.filter(chapter.entries, 'eligibleToWin').length > 1);
    }, [chapter, setEnabled])

    return (
        <Fragment>
            <p><TemplateSubTitle chapter={chapter} /> - Writing Closed</p>
            <h1>{chapter.template.name}</h1>
            {chapter.starcastingEnabled ? <p className='u-m-base-2'>Review student entries, give rewards and choose which could be winning entries. <br />(You can turn starcasting off in  settings)</p> :
                <p className="u-m-base-2">Review entries and make any edits. All entries will be included in the completed book.</p>}
             <StudentsEntriesList
                award={true}
                eligible={chapter.starcastingEnabled}
                status={chapter.status}
                entries={chapter.entries}
                visibleInCasting={chapter.starcastingEnabled}
                writing={true}
                chapter={chapter}
            /> 
        </Fragment>
    )
}

export default WritingClosed;