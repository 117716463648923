import React, { useState, Fragment, useEffect } from 'react';

import Output from '../../../../../components/EditorJsOutput';
import StudentsEntriesList from './StudentEntriesList';

import Tabs from '../../../../../components/Tabs';
import Tab from '../../../../../components/Tab';

import VocabModule from './VocabModule';
import TemplateSubTitle from './TemplateSubTitle';

const TABS = {
    showWinner: 0,
    // editWinner: 1,
    showAll: 2
}

const Completed = ({ chapter }) => {

    const [selectedTab, setSelectedTab] = useState(chapter.starcastingEnabled ? TABS.showWinner : TABS.showAll);
    const [winner, setWinner] = useState(chapter.entries.find(entry => { return entry.winner }));

    useEffect(() => {
        setWinner(chapter.entries.find(entry => { return entry.winner }))
    }, [chapter, setWinner])

    useEffect(() => {
        setSelectedTab(chapter.starcastingEnabled ? TABS.showWinner : TABS.showAll)
    }, [setSelectedTab, chapter])


    const escapeVocab = vocab => vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const highlightVocab = (text, vocab) => {

        let newText = text;
        const terms = vocab.map(v => escapeVocab(v.word));
        terms.sort((a, b) => b.length - a.length);
        let regEx = new RegExp(String.raw`\b(?:${terms.join('|')})\b`, 'gi');
        newText = newText.replace(regEx, (m) => `<span class='tagvocab'>${m}</span>`);
        return newText;
        
        // let newText = text;
        // vocab.map(v => {
        //     let exp = RegExp('\\b(' + escapeVocab(v.word) + ')\\b', 'i');
        //     newText = newText.replace(exp, `<span class=\"tagvocab\">${v.word}</span>`)

        // })
        // return newText;
    }

    const highlightEntry = (entryData) => {
        const vocab = chapter.words;
        entryData.blocks.forEach(block => {
            if (block.data.text && block.data.text.length !== 0) {
                block.data.text = highlightVocab(block.data.text, vocab)
            }
        });
        return entryData
    }


    return (
        <Fragment>
            <p><TemplateSubTitle chapter={chapter} /> - completed</p>
            <h1>{chapter.template.name}</h1>
            {
                chapter.starcastingEnabled && (
                    <Tabs className='u-m-base-3'>
                        <Tab onClick={() => setSelectedTab(TABS.showWinner)} selected={selectedTab === TABS.showWinner}>Winner</Tab>
                        {/* {!book.classPack.isWritingActivity &&
                            <Tab onClick={() => setSelectedTab(TABS.editWinner)} selected={selectedTab === TABS.editWinner}>Edit</Tab>
                        } */}
                        <Tab onClick={() => setSelectedTab(TABS.showAll)} selected={selectedTab === TABS.showAll}>All Students</Tab>
                    </Tabs>
                )
            }

            {
                chapter.starcastingEnabled && selectedTab === TABS.showWinner && (
                    <Fragment>

                        <h4 className='u-m-base-2'>Winner: <span className='heavy'>{winner.author.name}</span></h4>
                        {chapter.words?.length > 0 && <VocabModule entry={JSON.parse(winner.entry)} vocab={chapter.words} />}

                        <div className='card-entry u-m-top-2'>
                            {chapter.words?.length > 0 ?
                                <Output data={highlightEntry(JSON.parse(winner.entry))} /> :
                                <Output data={JSON.parse(winner.entry)} />
                            }
                        </div>
                    </Fragment>
                )
            }
            {/* {
                selectedTab === TABS.editWinner && <TeacherEdit entry={winner} />
            } */}
            {
                selectedTab === TABS.showAll && (
                    <StudentsEntriesList
                        writing={true}
                        voted={chapter.starcastingEnabled}
                        votes={chapter.starcastingEnabled}
                        award={true}
                        eligible={chapter.starcastingEnabled}
                        visibleInCasting={chapter.starcastingEnabled}
                        status={chapter.status}
                        entries={chapter.entries}
                        chapter={chapter}
                    />
                )
            }
        </Fragment>
    );
}

export default Completed;
