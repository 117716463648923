import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { MATH_TOPIC_QUERY, MATH_SOCIAL_TEMPLATES, ADD_WRITING_TASK } from "./services/graphql";
import { Loader, FormErrors } from "@axeedge/go-teacher-components";
import { GET_CLASS_QUERY } from "../../services/class/graphql";
import CreatePoll from "./scenes/Polls/CreatePoll";
import { navigate } from "@reach/router";
import { PlusCircle, ArrowLeft, CheckCircle, Frown, ThumbsUp, ThumbsDown, Meh, Smile, Star } from 'react-feather';

import styles from './Project.module.scss';
import cx from 'classnames';
import Nav from "./components/Nav";

const NewProject = ({ classId, topicId, curriculum, close, mathProject = null, classPreview = false, addItemType, setAddItemType }) => {

    const [viewAllWords, setViewAllWords] = useState(false);

    const hasTemplateStarted = (template) => {
        return mathProject && mathProject.chapters.find(ch => ch.template.id === template.id)
    }

    const { data, loading, error } = useQuery(MATH_TOPIC_QUERY, {
        variables: {
            mathTopicId: topicId
        }
    });

    const [formErrors, setFormErrors] = useState([]);

    const [addWritingTask, { loading: addingChapter }] = useMutation(ADD_WRITING_TASK, {
        onCompleted: ({ addWritingTask }) => {
            if (addWritingTask.errors && addWritingTask.errors.length !== 0) {
                setFormErrors(addWritingTask.errors);
                return;
            }
            if (addWritingTask && addWritingTask.mathProjectChapter.project && addWritingTask.mathProjectChapter && addWritingTask.mathProjectChapter.id) {
                const projectId = addWritingTask.mathProjectChapter.project.id;
                //setShowAddWritingTask(false);
                close();
                navigate(`/project/${projectId}/${addWritingTask.mathProjectChapter.id}`)
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId } }]
    });

    const { data: socialData, loading: socialLoading, error: socialError } = useQuery(MATH_SOCIAL_TEMPLATES);

    if (loading || socialLoading) {
        return <Loader />
    }

    if (error || socialError) {
        return <p>{error.message}</p>
    }
    if (data && socialData) {

        const { mathTopic } = data;
        const { mathSocialTemplates } = socialData;

        return (
            <>
                {classPreview &&
                    <>
                        <div className='page-header'>
                            <div className='page-header__content'>
                                {(!mathProject || (mathProject && mathProject.chapters.length > 0)) &&
                                    <button className='page-header__back' onClick={close}>
                                        <ArrowLeft className='page-header__back__icon' />
                                    </button>
                                }
                                <div className='page-header__content__inner'>
                                    <p>{curriculum?.name} {mathTopic.unit.course.name} - {mathTopic.unit.name}</p>
                                    <h2 className='page-header__content__title'>Topic {mathTopic.topicCode}: {mathTopic.name}</h2>

                                </div>
                            </div>
                        </div>
                    </>
                }

                <div className={cx({ 'u-m-top-3': mathProject?.items.length === 0 })}>
                    {!addItemType ? (
                        <>
                            <h3>Please select a category...</h3>
                            <div className='row u-m-top-2'>
                                <div className="col-md u-display-flex">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3>Add writing task</h3>
                                            <div className={styles.addItemHeader}>
                                                <p>A writing assignment that is a long-form, written response to a math question that you select.  You can optionally enable Star Casting, an anonymous peer review where students can rate other written responses in the class.</p>
                                                <button className="btn-reset" onClick={() => setAddItemType('task')}><PlusCircle /></button>
                                            </div>
                                            <p className="u-m-base-1">Choose from the topic's Essential Questions to test understanding of key concepts and terms. These use peer review (StarCasting) to create a rich environment for discussion and broader understanding.</p>
                                            <p>The Social Emotional questions allow students provide private feedback to the teacher in a safe way.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md u-display-flex">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3>Add Poll</h3>
                                            <div className={styles.addItemHeader}>
                                                <p>Conduct a short poll to quickly understand confidence or comprehension levels.</p>
                                                <button className="btn-reset" onClick={() => setAddItemType('poll')}><PlusCircle /></button>
                                            </div>
                                            <p className="u-m-base-1">Polls allow students to respond to a teacher written question with a quick emoji and, optionally, a brief comment.</p>
                                            <p className="u-m-base-2">Set whatever poll you like and choose from the following responses:</p>
                                            <ul className={styles.pollExampleIcons}>
                                                <li className="u-m-right-3"><Smile /> <Meh /><Frown /></li>
                                                <li className="u-m-right-3"><Star /> <Star /><Star /></li>
                                                <li className="u-m-right-3"><ThumbsUp /> <ThumbsDown /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : addItemType && addItemType === 'poll' ? (
                        <CreatePoll
                            mathProject={mathProject}
                            close={() => { setAddItemType(null); close() }}
                            curriculum={curriculum}
                            mathTopic={mathTopic}
                            classId={classId}
                        />
                    ) : addItemType && addItemType === 'task' && (
                        <>
                            {addingChapter ? <Loader /> :
                                <div className={styles.previewContainer}>
                                    <div className={styles.previewQuestions}>
                                        <div className="u-display-flex u-align-center u-justify-between u-m-base-2">
                                            <h1 className="u-m-base-1">Add New Writing Task</h1>
                                            <button className="btn-danger" onClick={() => { setAddItemType(null); close() }}>Cancel</button>
                                        </div>
                                        {formErrors && <FormErrors errors={formErrors} />}
                                        <h4>Essential Questions</h4>
                                        <p className="u-m-base-3">These use voting/peer review and come pre-loaded with the topic vocabulary</p>
                                        <div className={styles.templatesGrid}>
                                            {mathTopic.templates.map((template, i) => (
                                                <div key={`q-${template.id}`} className={styles.templatesGridItem}>
                                                    <div className={cx(styles.questionItem, { [styles.questionItemDone]: hasTemplateStarted(template) })}>
                                                        <div className={styles.questionItemInner}>
                                                            <div className={styles.questionItemHeader}>
                                                                <p>EQ{template.orderId}</p>
                                                                {hasTemplateStarted(template) && <CheckCircle />}
                                                            </div>
                                                            <p>{template.name}</p>
                                                        </div>
                                                        <div>
                                                            <div className={styles.starCastLabel}>
                                                                <span>StarCasting</span>
                                                                <span className={styles.starCastIcon}><CheckCircle size='18' /> ON</span>
                                                            </div>
                                                            <button className={styles.questionItemBtn}
                                                                onClick={() => addWritingTask({
                                                                    variables: {
                                                                        studentsClassId: classId,
                                                                        mathTemplateId: template.id,
                                                                        mathTopicId: topicId
                                                                    }
                                                                })}
                                                            >
                                                                <PlusCircle stroke="#4a4a4a" className="u-m-right-1" />
                                                                <span>Add To Project</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <h4>Social Emotional Questions</h4>
                                        <p className="u-m-base-3">These do not use peer review - they are designed to capture private feelings and emotions.</p>
                                        <div className={styles.templatesGrid}>
                                            {mathSocialTemplates.map(template => (
                                                <div key={`qem-${template.id}`} className={styles.templatesGridItem}>
                                                    <div className={cx(styles.questionItem, { [styles.questionItemDone]: hasTemplateStarted(template) })}>
                                                        <div className={styles.questionItemInner}>
                                                            <div className={styles.questionItemHeader}>
                                                                <p>SE{template.orderId}</p>
                                                                {hasTemplateStarted(template) && <CheckCircle />}
                                                            </div>
                                                            <p>{template.name}</p>
                                                        </div>
                                                        <button className={styles.questionItemBtn}
                                                            onClick={() => addWritingTask({
                                                                variables: {
                                                                    studentsClassId: classId,
                                                                    mathTemplateId: template.id,
                                                                    mathTopicId: topicId
                                                                }
                                                            })}
                                                        >
                                                            <PlusCircle stroke="#4a4a4a" className="u-m-right-1" />
                                                            <span>Add To Project</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.previewSide}>
                                        <h2>Topic Vocabulary</h2>
                                        <ul className={cx(styles.wordBank, 'u-m-base-3')}>
                                            {mathTopic.words.slice(0, 6).map((vocab, i) => (
                                                <li key={`${i}`} className={styles.wordBankItem}>
                                                    {vocab.word}
                                                </li>
                                            ))}
                                            {viewAllWords && mathTopic.words.slice(6, mathTopic.words.length).map((vocab, i) => (
                                                <li key={`${i}`} className={styles.wordBankItem}>
                                                    {vocab.word}
                                                </li>
                                            ))}
                                        </ul>
                                        {!viewAllWords && <span className={styles.viewAllWords} onClick={() => setViewAllWords(true)}>View All Words ({mathTopic.words.length})</span>}
                                    </div>
                                </div>
                            }
                        </>
                    )}
                </div>
            </>
        )
    }
    return null;
}

export default NewProject;