import React, { Fragment, useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Printer } from 'react-feather';
import { AuthContext } from '../../services/auth/AuthProvider';
import { Loader } from '@axeedge/go-teacher-components';
import {navigate} from '@reach/router'
import { GET_CLASS_QUERY } from '../../services/class/graphql';
import PrintableStudentsList from './scenes/Students/components/PrintableStudentsList';
import Courses from '../Courses';
import {ArrowLeft} from 'react-feather'

const Class = ({ classId, location }) => {


    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const [showPrintableList, setShowPrintableList] = useState(false);
    const [viewTopic, setViewTopic] = useState(null);
    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: classId
        },
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass) {
        if (showPrintableList) {
            return <PrintableStudentsList school={teacher.school} classId={data.studentsClass.id} exitPrintMode={() => setShowPrintableList(false)} />
        }
        return (
            <Fragment>
                {!viewTopic &&
                <div className='page-header'>
                    <div className='page-header__content'>
                        {teacher.studentsClasses.length > 1 &&
                            <button className='page-header__back' onClick={() => navigate(`/`)}>
                                <ArrowLeft className='page-header__back__icon' />
                            </button>
                        }
                        <div className='page-header__content__inner'>
                            <h2 className='page-header__content__title'>{data.studentsClass.name} &ndash; Year {data.studentsClass.description} </h2>
                            <p className='page-header__content__link' onClick={() => setShowPrintableList(true)}><Printer size="10" /> Show Printable Students List</p>
                        </div>
                    </div>
                </div>
                }
                {teacher.school.curriculum && <Courses curriculum={teacher.school.curriculum} classProjects={data.studentsClass.mathProjects} classId={classId} setViewTopic={setViewTopic} viewTopic={viewTopic} />}
            </Fragment>
        );
    }
    return null;
}

export default Class;