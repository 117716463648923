import React from "react";
import cx from 'classnames';
import ReactTooltip from "react-tooltip";
import styles from './Courses.module.scss';
import { File, BarChart2 } from 'react-feather';
import { navigate } from '@reach/router';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import { POLL_STATUS } from "../../services/constants";

const Topic = ({ topic, hasProject, setViewTopic }) => {

    const topicBtnAction = () => {
        if (hasProject) {
            const projectId = hasProject.id;
            const item = hasProject.items.length > 0 && hasProject.items[0];
            navigate(item.chapter ? `/project/${projectId}/${item.chapter.id}` : item.poll ? `/project/${projectId}/poll/${item.poll.id}` : `/project/${projectId}`);
        } else {
            setViewTopic(topic)
        }
    }

    const renderProgressBar = () => {
        const completedChapters = hasProject && hasProject.chapters.filter(c => c.status === CHAPTER_STATUS.completed).length;
        if (hasProject && hasProject.chapters.length > 0) {
            return <div className={styles.taskCompletedBar} style={{ width: `${(completedChapters / hasProject.chapters.length) * 100}%` }} />
        }
        return null;
    }


    const renderTaskIcon = () => {
        const completedChapters = hasProject && hasProject.chapters.filter(c => c.status === CHAPTER_STATUS.completed).length;
        if (hasProject) {
            if (hasProject.chapters.length === 0) {
                return <div className={styles.statsItem}><File /></div>
            }
            return (
                <div
                    data-tip={`Writing Tasks: ${completedChapters}/${hasProject.chapters.length}`}
                    className={cx(styles.statsItemActive, { [styles.statsItemCompleted]: hasProject.chapters.length === completedChapters })}
                >
                    <ReactTooltip />
                    <File />
                </div>
            )
        }
        return null
    }

    const renderPollIcon = () => {
        const closedPolls = hasProject && hasProject.polls.filter(poll => poll.status === POLL_STATUS.closed).length;
        if (hasProject) {
            if (hasProject.polls.length === 0) {
                return <div className={styles.statsItem}><BarChart2 /></div>
            }
            return (
                <div
                    data-tip={`Polls: ${closedPolls}/${hasProject.polls.length}`}
                    className={cx(styles.statsItemActive, { [styles.statsItemCompleted]: hasProject.polls.length === closedPolls })}
                >
                    <ReactTooltip />
                    <BarChart2 />
                </div>
            )
        }
        return null
    }

    return (
        <>
            <div onClick={() => topicBtnAction()} className={styles.unitTopicsItem}>

                <div className={styles.unitTopicsItemContent}>
                    <h4 className={styles.unitTopicsItemTitle}>
                        <span className={cx(styles.unitTopicsLabel, { [styles.unitTopicsLabelActive]: hasProject && hasProject.chapters.length > 0 })}>{topic.topicCode}</span>
                        {topic.name}
                    </h4>
                    <div className={styles.stats}>
                        {renderPollIcon()} {renderTaskIcon()}
                    </div>
                </div>
                {renderProgressBar()}
            </div>
        </>
    )
}

export default Topic;