import React, { useState, useRef, useEffect } from "react";
import { Check, PlusCircle, Plus } from "react-feather";
import { Link } from '@reach/router';
import cx from 'classnames';
import styles from '../Project.module.scss';
import { CHAPTER_STATUS } from "@axeedge/go-shared-utils";
import { POLL_STATUS } from "../../../services/constants";

import { useLocation } from "react-use";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Nav = ({ mathProject, setViewTopic, viewTopic, addItemType, setAddItemType }) => {

    const [showMenu, setShowMenu] = useState(false);
    const isActive = ({ isCurrent }) => {
        return isCurrent && !viewTopic ? { className: cx(styles.taskNavLink, styles.taskNavLinkActive) } : { className: styles.taskNavLink }
    }

    const location = useLocation();

    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const handleAddItem = (item) => {
        setViewTopic(mathProject.topic);
        setAddItemType(item);
        setShowMenu(false);
    }

    useEffect(() => {
        setAddItemType(null);
        setShowMenu(false);
    }, [location])

    const polls = mathProject.items.filter(el => el.poll);

    return (
        <ul className={cx(styles.taskNav, 'hidden-print')}>
            {mathProject.items.map(item => (
                <li key={item.id}>
                    <Link
                        to={item.chapter ? `/project/${mathProject.id}/${item.chapter.id}` : `/project/${mathProject.id}/poll/${item.poll.id}`}
                        getProps={isActive}
                        className={styles.taskNavLink}
                        onClick={() => setViewTopic(false)}
                    >   
                        <Tippy content={item.chapter ? item.chapter.template.name : item.poll.question}>
                        {item.chapter ?
                            <span className={cx(styles.taskNavLinkInner, { [styles.taskNavLinkInnerDraft]: item.chapter.status === CHAPTER_STATUS.new })}>
                                {item.chapter.template.isSocial ? 'SE' : 'EQ'}{item.chapter.template.orderId}
                                {item.chapter.status === CHAPTER_STATUS.completed && <span className={styles.taskNavDoneIcon}><Check size="13" /></span>}
                            </span> :
                            <span className={cx(styles.taskNavLinkInner, { [styles.taskNavLinkInnerDraft]: item.poll.status === POLL_STATUS.new })}>
                                {item.poll.status === POLL_STATUS.closed && <span className={styles.taskNavDoneIcon}><Check size="13" /></span>}
                                Poll{polls.findIndex(el => el.poll.id === item.poll.id) + 1}
                            </span>
                        }
                        </Tippy>
                    </Link>
                </li>
            ))}
            <li ref={ref} className={styles.taskNavNewItem}>
                <button onClick={() => setShowMenu(!showMenu)} className={cx(styles.taskNavAdd, addItemType && styles.taskNavLinkActive)}><Plus /></button>
                {showMenu &&
                    <div className={styles.taskNavNewItemDropdown}>
                        <ul>
                            <li>
                                <button className={styles.taskNavNewItemLink} onClick={() => handleAddItem('task')}>
                                    <div className={styles.taskNavNewItemMain}>
                                        <p className='lead'>Add Writing Task</p>
                                        <p className="small">A writing assignment where students provide a long-form answer with an optional peer-review process (Star Casting)</p>
                                    </div>
                                    <PlusCircle />
                                </button>
                            </li>
                            <li>
                                <button className={styles.taskNavNewItemLink} onClick={() => handleAddItem('poll')}>
                                    <div className={styles.taskNavNewItemMain}>
                                        <p className='lead'>New Poll</p>
                                        <p className="small">Conduct a short poll to quickly understand confidence or comprehension levels.</p>
                                    </div>
                                    <PlusCircle />
                                </button>
                            </li>
                        </ul>
                    </div>
                }
            </li>
        </ul>
    )
}
export default Nav;