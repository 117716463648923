import { gql } from 'apollo-boost';

export const MATH_CURRICULUM= gql`
query mathCurriculum($mathCurriculumId: ID!) {
    mathCurriculum(mathCurriculumId: $mathCurriculumId) {
        id
        name
        description
        courses {
            id
            name
            description
            units {
                id
                name
                unitCode
                course {
                    id
                    name
                }
                topics {
                    id
                    name
                    description
                    topicCode
                }
            }
        }
    }
}
`;

export const MATH_CURRICULUM_BY_CODE = gql`
query mathCurriculumByCode($mathCurriculumCode: String!) {
    mathCurriculumByCode(mathCurriculumCode: $mathCurriculumCode) {
        id
        code
        name
        description
        courses {
            id
            name
            description
            units {
                id
                name
                unitCode
                course {
                    id
                    name
                }
                topics {
                    id
                    name
                    description
                    topicCode
                }
            }
        }
    }
}
`;