import React from 'react';
import { ActionCableConsumer, ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import { getBoomMathCableEndpoint } from '@axeedge/go-shared-utils';

export const ActionCableWrapper = ({ children }) => {

    return (
        <ActionCableProvider url={getBoomMathCableEndpoint()}>
            {children}
        </ActionCableProvider>
    );
};


export const NotifCableConsumer = ({ id, onForcedLogout, onLogout }) => {
    return (
        <ActionCableConsumer
            channel={{
                channel: 'NotificationsChannel',
                id
            }}
            onReceived={(message) => {
                if (message.logged_out) {
                    onLogout();
                }

                if (message.forced_logged_out && onForcedLogout) {
                    onForcedLogout();
                }
            }}
        ></ActionCableConsumer>
    )
}