import React from "react";
import _ from 'lodash';
import cx from 'classnames';
import styles from './VocabModule.module.scss';

const VocabModule = ({ entry, vocab}) => {

    const escapeVocab = (vocab) => {
        return vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const getVocabUsed = (vocabs) => {
        let allTextBlocks = [];
        entry.blocks.forEach(b => {
            if (b.data.text && b.data.text.length !== 0) {
                allTextBlocks.push(b.data.text)
            }
        });
        const text = allTextBlocks.join(' '); //join all text blocks
        const cleanText = text.replace(/<\/?[^>]+(>|$)/g, ""); //strip tags
        const vocabUsed = [];
        vocabs.forEach(vocab => {
            const exp = RegExp('\\b(' + escapeVocab(vocab.word) + ')\\b', 'gi');
            if (_.words(cleanText, exp).length !== 0) {
                vocabUsed.push(vocab);
            };
        })
        return vocabUsed;
    }

    const vocabModule = (vocabs, vocabUsed) => {
        if (vocabs.length > 0) {
            return (
                <>
                    <p>Word Bank: <span className="">{vocabUsed.length}/{vocabs.length}</span> </p>
                    <ul className={styles.vocabList}>
                        {vocabs.map((vocab, i) => <li key={`vocab-${i}`} className={cx(styles.vocabListItem, { [styles.vocabListItemUsed]: vocabUsed.indexOf(vocab) !== -1 })}>{vocab.word}</li>)}
                    </ul>
                </>
            )
        }
        return null;
    }


    return(
        <>
            {vocabModule(vocab, getVocabUsed(vocab))}
        </>
    )
}

export default VocabModule;