import React, { useState, useContext } from "react";
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import styles from '../../../Project.module.scss';
import Writing from "./Writing";
import Sidebar from "./Sidebar";
import VotingSetup from "./VotingSetup";
import WritingClosed from "./WritingClosed";
import Voting from './Voting';
import VotingClosed from "./VotingClosed";
import TaskSettings from "./TaskSettings";
import Completed from "./Completed";
import { AuthContext } from "../../../../../services/auth/AuthProvider";
import PrintableStudentsList from "../../../../Class/scenes/Students/components/PrintableStudentsList";


const TaskChapter = ({ chapter }) => {

    const [enabled, setEnabled] = useState(true);
    const { currentUser } = useContext(AuthContext);


    const [editTask, setEditTask] = useState(false);
    const [showPrintableList, setShowPrintableList] = useState(false);



    const getChapterContent = () => {
        if (!chapter) {
            return 'Nothing here'
        }
        switch (chapter.status) {
            // case CHAPTER_STATUS.teacher_chapter:
            //     return <TeacherChapter chapter={chapter} enabled={enabled} setEnabled={setEnabled} />
            // case CHAPTER_STATUS.writing_ready:
            //     return <WritingSetup chapter={chapter} enabled={enabled} setEnabled={setEnabled} />;
            case CHAPTER_STATUS.new:
                return <TaskSettings chapter={chapter} />;
            case CHAPTER_STATUS.writing:
                return <Writing chapter={chapter} />;
            case CHAPTER_STATUS.writing_closed:
                return <WritingClosed chapter={chapter} setEnabled={setEnabled} />;
            case CHAPTER_STATUS.voting_ready:
                return <VotingSetup chapter={chapter} />;
            case CHAPTER_STATUS.voting:
                return <Voting chapter={chapter} />;
            case CHAPTER_STATUS.voting_closed:
                return <VotingClosed chapter={chapter} />;
            case CHAPTER_STATUS.completed:
                return <Completed chapter={chapter} />;
            default:
                return 'Not available'
        }
    }

    if (showPrintableList) {
        return <PrintableStudentsList classId={chapter.project.studentsClass.id} school={currentUser.school} exitPrintMode={() => setShowPrintableList(false)} />

    }
    return (
        <div className={styles.task}>
            {editTask ? <TaskSettings editMode={true} closeEdit={() => setEditTask(false)} chapter={chapter} /> :
                <>
                    <div className={styles.taskContent}>
                        {getChapterContent()}
                    </div>
                    <div className={styles.taskSidebar}>
                        <Sidebar
                            setEditTask={() => setEditTask(true)} 
                            chapter={chapter}
                            enabled={enabled}
                            setShowPrintableList={setShowPrintableList}
                        />
                    </div>
                </>

            }
        </div>
    );
}

export default TaskChapter;