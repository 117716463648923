import React, { useState } from "react";
import { CLOSE_POLL, POLL_QUERY, REOPEN_POLL, START_POLL, DELETE_POLL } from '../../../services/graphql';
import { useMutation } from '@apollo/react-hooks';
import { Download } from "react-feather";
import loginImg from '../../../../../images/book/login.png';
import ImageFadeIn from "react-image-fade-in";
import { navigate } from "@reach/router";

import OverallSummary from './OverallSummary';
import { POLL_STATUS } from '../../../../../services/constants';
import { Modal, Button } from '@axeedge/go-teacher-components';

import cx from 'classnames';
import styles from '../Polls.module.scss';
import styles2 from '../../../Project.module.scss';
import { GET_CLASS_QUERY } from "../../../../../services/class/graphql";

const PollSidebar = ({ poll, setShowPrintableList }) => {

    const [confirmClose, setConfirmClose] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [startPoll, { loading: starting }] = useMutation(START_POLL, {
        onCompleted: ({ startPoll }) => {
            if (startPoll.errors && startPoll.errors.length !== 0) {
                alert(startPoll.errors[0]);
                return;
            }
        },
        refetchQueries: [{ query: POLL_QUERY, variables: { id: poll.id } }]
    });

    const [closePoll, { loading: closing }] = useMutation(CLOSE_POLL, {
        onCompleted: ({ closePoll }) => {
            if (closePoll.errors && closePoll.errors.length !== 0) {
                alert(closePoll.errors[0]);
                return;
            }
            if (closePoll?.poll?.id) {
                setConfirmClose(false);
            }
        }
    });

    const [reopenPoll, { loading: reopening }] = useMutation(REOPEN_POLL, {
        onCompleted: ({ reopenPoll }) => {
            if (reopenPoll.errors && reopenPoll.errors.length !== 0) {
                alert(reopenPoll.errors[0]);
                return;
            }
        },
        refetchQueries: [{ query: POLL_QUERY, variables: { id: poll.id } }]
    });


    const [deletePoll, { loading: deleting }] = useMutation(DELETE_POLL, {
        onCompleted: ({ deletePoll }) => {
            if (deletePoll.errors && deletePoll.errors.length !== 0) {
                alert(deletePoll.errors[0]);
                return;
            }
            if (deletePoll.deleted) {
                setConfirmDelete(false);
                const project = deletePoll.mathProject;
                const item = project.items.length > 0 && project.items[0];
                navigate(item.chapter ? `/project/${project.id}/${item.chapter.id}` : item.poll ? `/project/${project.id}/poll/${item.poll.id}` : `/project/${project.id}`);
            }
        },
        refetchQueries:[{query: GET_CLASS_QUERY, variables: {id: poll.mathProject.studentsClass.id}}]
    });

    return (
        <>
            <div className={cx(styles.pollSidebarPanel, 'u-m-base-3')}>
                {poll.status === POLL_STATUS.new &&
                    <Button disabled={starting} fullWidth onClick={() => startPoll({
                        variables: {
                            pollId: poll.id,
                            mathProjectId: poll.mathProject.id
                        }
                    })}>Start poll</Button>
                }

                {poll.status === POLL_STATUS.open &&
                    <Button fullWidth onClick={() => setConfirmClose(true)}>End poll</Button>
                }

                {poll.status === POLL_STATUS.closed &&
                    <Button disabled={reopening} fullWidth onClick={() => reopenPoll({
                        variables: {
                            pollId: poll.id,
                            mathProjectId: poll.mathProject.id
                        }
                    })}>Reopen poll</Button>
                }

                <ul className={cx(styles2.resources, 'u-m-top-3')}>
                    <li>
                        <button className={cx('btn-reset', styles2.resourcesLink)} onClick={() => setShowPrintableList(true)}>
                            <div className={styles2.resourcesImg}><ImageFadeIn src={loginImg} alt='parent letters' /></div>
                            <p>Students Logins</p>
                            <Download />
                        </button>
                    </li>
                </ul>
                {poll.status === POLL_STATUS.new &&
                    <button className="btn-danger u-d-block u-full-width" onClick={() => setConfirmDelete(true)}>Delete poll</button>
                }
            </div>

            {poll.status !== POLL_STATUS.new && (
                <div className={cx(styles.overallSummary, { [styles.overallSummaryStars]: poll.pollReactionSet.reactionSet === 'stars' })}>
                    <OverallSummary poll={poll} />
                </div>
            )}

            {
                confirmDelete && (
                    <Modal closeModal={() => setConfirmDelete(false)}>
                        <div className="u-text-center">
                            <h1 className="u-m-base-2">Are you sure?</h1>
                            <Button disabled={deleting} onClick={() => deletePoll({
                                variables: {
                                    mathProjectId: poll.mathProject.id,
                                    pollId: poll.id
                                }
                            })} className='u-m-right-2'>{deleting ? 'Saving...' : 'Yes'}</Button>
                            <Button outline onClick={() => setConfirmDelete(false)}>No</Button>
                        </div>
                    </Modal>
                )
            }

            {
                confirmClose && (
                    <Modal closeModal={() => setConfirmClose(false)}>
                        <div className="u-text-center">
                            <h1 className="u-m-base-2">Are you sure?</h1>
                            <Button disabled={closing} onClick={() => closePoll({
                                variables: {
                                    pollId: poll.id,
                                    mathProjectId: poll.mathProject.id
                                }
                            })} className='u-m-right-2'>{closing ? 'Saving...' : 'Yes'}</Button>
                            <Button outline onClick={() => setConfirmClose(false)}>No</Button>
                        </div>
                    </Modal>
                )
            }
        </>
    )

}

export default PollSidebar