import React, { Fragment } from 'react';
import { Slash } from 'react-feather';
import TemplateSubTitle from './TemplateSubTitle';
import StudentsEntriesList from './StudentEntriesList';

const VotingClosed = ({ chapter }) => {

    return (
        <Fragment>
            <p><TemplateSubTitle chapter={chapter} /> - StarCasting ended</p>
            <h1>{chapter.template.name}</h1>
            <p className='u-m-base-3'>Students are no longer able to StarCast but we won't show them the winner.</p>
            <h4>Results</h4>
            <StudentsEntriesList
                onlyEligible={true}
                ranked={true}
                votes={true}
                status={chapter.status}
                entries={chapter.entries}
                chapter={chapter}
            />
            {chapter.entries.filter(entry => { return !entry.eligibleToWin && !entry.hiddenToCasting }).length !== 0 && (
                <Fragment>
                    <p className='icon-title u-m-top-4 u-m-base-1'><Slash className='u-m-right-1' /> Uneligible Entries shown in StarCasting</p>
                    <StudentsEntriesList
                        onlyUnEligible={true}
                        status={chapter.status}
                        entries={chapter.entries}
                        votes={true}
                        chapter={chapter}
                    />
                </Fragment>
            )}
        </Fragment>
    )
}

export default VotingClosed;
