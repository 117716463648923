import React, { useState, useEffect } from "react";
import TaskChapter from "./components/TaskChapter";
import { MATH_PROJECT_CHAPTER_QUERY } from "../../services/graphql";
import { useQuery } from "@apollo/react-hooks";
import { Loader } from "@axeedge/go-teacher-components";
import { usePageVisibility } from 'react-page-visibility';

const TaskHolder = ({ chapterId }) => {
    const [pollInterval, setPollInterval] = useState(0);
  
    const isVisible = usePageVisibility();

    useEffect(() => {
        const startPolling = () => setPollInterval(20000)
        const stopPolling = () => setPollInterval(0)
        isVisible ? startPolling() : stopPolling()
    }, [isVisible]);

    const { data, loading, error } = useQuery(MATH_PROJECT_CHAPTER_QUERY, {
        variables: {
            mathProjectChapterId: chapterId
        },
        pollInterval
    });


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.mathProjectChapter) {
        const { mathProjectChapter } = data;
        return (
            <>
                <TaskChapter chapter={mathProjectChapter} />
            </>
        )
    }
    return null

}

export default TaskHolder;