import React, { useState, Fragment } from 'react';

import Tabs from '../../../../../components/Tabs';
import Tab from '../../../../../components/Tab';
import StudentsEntriesList from './StudentEntriesList';
import TemplateSubTitle from './TemplateSubTitle';
import { Slash } from 'react-feather';

const Voting = ({ chapter }) => {
    const [showResults, setShowResults] = useState(false);
    return (
        <Fragment>
            <p><TemplateSubTitle chapter={chapter} /> - Starcasting</p>
            <h1>{chapter.template.name}</h1>
            <p className='u-m-base-3'>View who's voted and the current results.</p>
            <Tabs className='u-m-base-4'>
                <Tab onClick={() => setShowResults(false)} selected={!showResults}>Voters</Tab>
                <Tab onClick={() => setShowResults(true)} selected={showResults}>Preview Results</Tab>
            </Tabs>
            {
                showResults ?
                    (
                        <Fragment>
                            <StudentsEntriesList
                                onlyEligible={true}
                                ranked={true}
                                status={chapter.status}
                                entries={chapter.entries}
                                votes={false}
                                chapter={chapter}
                            />

                            {chapter.entries.filter(entry => { return !entry.eligibleToWin && !entry.hiddenToCasting && entry.text }).length !== 0 && (
                                <Fragment>
                                    <p className='icon-title u-m-top-4 u-m-base-1'><Slash className='u-m-right-1' /> Uneligible Entries shown in StarCasting</p>
                                    <StudentsEntriesList
                                        onlyUnEligible={true}
                                        status={chapter.status}
                                        entries={chapter.entries}
                                        votes={true}
                                        chapter={chapter}
                                    />
                                </Fragment>
                            )}

                        </Fragment>

                    ) :
                    (
                        <StudentsEntriesList
                            eligible={true}
                            visibleInCasting={true}
                            voted={true}
                            status={chapter.status}
                            entries={chapter.entries}
                            chapter={chapter}
                        />
                    )
            }
        </Fragment>
    );
}

export default Voting;
