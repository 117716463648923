import React from 'react';

import StudentsEntriesList from './StudentEntriesList';
import TemplateSubTitle from './TemplateSubTitle';

const Writing = ({ chapter }) => {
    return (
        <div className="chapter__writing">
            <p><TemplateSubTitle chapter={chapter} /> - Writing</p>
            <h1>{chapter.template.name}</h1>
            <StudentsEntriesList
                award={true}
                eligible={chapter.starcastingEnabled}
                status={chapter.status}
                entries={chapter.entries}
                visibleInCasting={chapter.starcastingEnabled}
                writing={true}
                chapter={chapter}
            />

        </div>
    );
}

export default Writing;