import React, { useState, Fragment } from 'react';

import { Button } from '@axeedge/go-teacher-components';

import StudentsEntriesList from './StudentEntriesList';

const TieBreak = ({ chooseWinner, entries, chapter }) => {
    const [winner, setWinner] = useState(null);

    return (
        <Fragment>
            <h1>Tiebreak</h1>
            <p>{entries.length} entries have the same average rating and the same number of votes. To view each entry click on the pupil's name. Please choose a winner:</p>
            <StudentsEntriesList chapter={chapter} entries={entries} checkbox={true} onSetChecked={setWinner} />
            <Button
                onClick={() => {
                    chooseWinner({
                        variables: {
                            mathProjectChapterId: winner.chapter.id,
                            studentEntryId: winner.id
                        }
                    })
                }}
                className='u-m-top-2'
                disabled={!winner}
            >
                Choose Winner
            </Button>
        </Fragment>

    )
}

export default TieBreak;