import React, { useState, useRef, useEffect } from "react";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMutation } from "@apollo/react-hooks";
import TextareaAutosize from 'react-textarea-autosize';
import styles from '../../../Project.module.scss';
import cx from 'classnames';
import { XCircle, AlertCircle, X, RefreshCw } from "react-feather";
import { CHAPTER_STATUS } from "@axeedge/go-shared-utils";
import { EDIT_MATH_PROJECT_CHAPTER, ADD_WORD_TO_CHAPTER, DELETE_WORD_FROM_CHAPTER, MATH_PROJECT_CHAPTER_QUERY, RESET_VOCAB } from "../../../services/graphql";
import { Button, FormErrors, Modal } from "@axeedge/go-teacher-components";
import SaveConfirm from "../../../../../components/SaveConfirm";
import TemplateSubTitle from "./TemplateSubTitle";
import loader from '../../../../../images/loader.svg'

const TaskSettings = ({ chapter, closeEdit, editMode = false }) => {


    const [chapterGuidance, setChapterGuidance] = useState(chapter.guidance);
    const [scEnabled, setScEnabled] = useState(chapter.starcastingEnabled);
    const [vocabs, setVocabs] = useState(chapter.words);

    const [showScModal, setShowScModal] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [wordToDelete, setWordToDelete] = useState(null);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);


    useEffect(() => {
        setChapterGuidance(chapter.guidance);
        setScEnabled(chapter.starcastingEnabled);
        setVocabs(chapter.words);
    }, [chapter.id])

    const [editMathProjectChapter, { loading: editing }] = useMutation(EDIT_MATH_PROJECT_CHAPTER, {
        onCompleted: ({ editMathProjectChapter }) => {
            if (editMathProjectChapter.errors && editMathProjectChapter.errors.length !== 0) {
                setFormErrors(editMathProjectChapter.errors);
                return;
            }
            if (editMathProjectChapter && editMathProjectChapter.chapter && editMathProjectChapter.chapter.id) {
                editMode ? closeEdit() : setShowSaveConfirm(true);
            }
        },
        refetchQueries: [{ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } }]
    });

    const [addVocabularyWordToChapter] = useMutation(ADD_WORD_TO_CHAPTER, {
        onCompleted: ({ addVocabularyWordToChapter }) => {
            if (addVocabularyWordToChapter.errors && addVocabularyWordToChapter.errors.length !== 0) {
                setFormErrors(addVocabularyWordToChapter.errors);
                return;
            }
            if (addVocabularyWordToChapter && addVocabularyWordToChapter.chapter && addVocabularyWordToChapter.chapter.id) {
                setVocabs([...addVocabularyWordToChapter.chapter.words]);
            }
        },
        refetchQueries: [{ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } }]

    });

    const [resetVocabularyWords, { loading: resetting }] = useMutation(RESET_VOCAB, {
        onCompleted: ({ resetVocabularyWordsForChapter }) => {
            if (resetVocabularyWordsForChapter.errors && resetVocabularyWordsForChapter.errors.length !== 0) {
                setFormErrors(resetVocabularyWordsForChapter.errors);
                return;
            }
            if (resetVocabularyWordsForChapter && resetVocabularyWordsForChapter.chapter && resetVocabularyWordsForChapter.chapter.id) {
                setVocabs([...resetVocabularyWordsForChapter.chapter.words]);
            }
        },
        refetchQueries: [{ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } }]

    });

    const [deleteVocabularyWordFromChapter, { loading: deleting }] = useMutation(DELETE_WORD_FROM_CHAPTER, {
        onCompleted: ({ deleteVocabularyWordFromChapter }) => {
            if (deleteVocabularyWordFromChapter.errors && deleteVocabularyWordFromChapter.errors.length !== 0) {
                setFormErrors(deleteVocabularyWordFromChapter.errors);
                return;
            }
            if (deleteVocabularyWordFromChapter && deleteVocabularyWordFromChapter.deleted) {
                setVocabs(vocabs.filter(v => v.id !== wordToDelete.id));
                setWordToDelete(null);
            }
        },
        refetchQueries: [{ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } }]

    });



    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onSubmit'
    });


    useEffect(() => {
        setValue('guidance', chapterGuidance);
    }, [setValue, chapterGuidance]);

    const tagInput = useRef(null);

    const addVocabs = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (vocabs.find(word => word.word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            addVocabularyWordToChapter({
                variables: {
                    mathProjectChapterId: chapter.id,
                    vocabularyWord: val.trim()
                }
            })
            tagInput.current.value = null;
        }
    };


    const onSubmit = values => {
        const { guidance } = values;
        editMathProjectChapter({
            variables: {
                mathProjectChapterId: chapter.id,
                guidance,
                starcastingEnabled: scEnabled
            }
        });
    }

    return (
        <>
            <div className={editMode ? styles.taskSettingsContainer : ''}>
                <div className={editMode ? styles.taskInner : ''}>
                    {editMode && (
                        <div className={styles.taskEditHeader}>
                            <h3>Task settings</h3>
                            <button className="btn-reset" onClick={closeEdit}><X /></button>
                        </div>
                    )}
                    <p><TemplateSubTitle chapter={chapter} /></p>
                    <h1>{chapter.template.name}</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p><b>Guidance notes</b></p>
                        <p className="small">Shown to students while they write</p>
                        {chapter.status <= CHAPTER_STATUS.writing ?
                            <div className='basic-form__group'>
                                <TextareaAutosize
                                    name='visibleGuidance'
                                    onChange={e => setChapterGuidance(e.target.value)}
                                    value={chapterGuidance || ''}
                                    placeholder='Chapter guidance'
                                    className='basic-form__text-box'
                                />
                                <input type='hidden' name='guidance' ref={register} defaultValue={chapter.guidance || ''} />
                            </div> :
                            <p>{chapterGuidance}</p>
                        }

                        <div className="u-display-flex">
                            <div>
                                <p><b>Word bank</b></p>
                                <p>Shown to students while they write</p>
                            </div>
                            {resetting ?
                                <img src={loader} className={styles.resetLoader} />
                                :
                                <button className={styles.resetBtn} onClick={() => resetVocabularyWords({ variables: { mathProjectChapterId: chapter.id } })}>RESET <RefreshCw style={{ marginLeft: '5px' }} /></button>
                            }
                        </div>
                        <ul className={styles.wordBank}>
                            {vocabs.map(word => (
                                <li key={`word-${word.id}`} className={styles.wordBankItem}>
                                    {word.word}
                                    {chapter.status <= CHAPTER_STATUS.writing &&
                                        <button
                                            disabled={deleting}
                                            type="button"
                                            onClick={() => {
                                                setWordToDelete(word);
                                                deleteVocabularyWordFromChapter({
                                                    variables: {
                                                        mathProjectChapterId: chapter.id,
                                                        vocabularyWordId: word.id
                                                    }
                                                });

                                            }}
                                            className={cx('btn-reset', styles.wordBankDelete)}>
                                            <XCircle />
                                        </button>
                                    }
                                </li>
                            ))}
                        </ul>

                        {chapter.status <= CHAPTER_STATUS.writing &&
                            <>
                                {!chapter.template.isSocial &&
                                    <p>Add to word bank</p>
                                }
                                <div className='basic-form__group--horizontal'>
                                    <input
                                        type="text"
                                        placeholder='Separate words with comma'
                                        className='basic-form__text-box'
                                        onKeyPress={(e) => addVocabs(e)}
                                        ref={tagInput}
                                    />
                                </div>
                            </>
                        }


                        {!chapter.template.isSocial &&
                            <div className='basic-form__group u-m-top-3'>
                                <label className='basic-form__text-label basic-form__text-label--auto heavy'>Enable StarCasting</label>
                                <input disabled={chapter.status >= CHAPTER_STATUS.voting} className={`switch ${chapter.status >= CHAPTER_STATUS.voting && 'switchDisabled'}`} type='checkbox' id='switch' checked={scEnabled} onChange={(e) => setScEnabled(!scEnabled)} value={scEnabled} />
                                <label className={`switchLabel ${chapter.status >= CHAPTER_STATUS.voting && 'switchLabelDisabled'}`} htmlFor='switch'>Enable StarCasting</label>
                                {<AlertCircle className='u-link u-m-left-2' onClick={() => setShowScModal(true)} />}
                            </div>
                        }
                        {formErrors && <FormErrors errors={formErrors} />}

                        {chapter.status <= CHAPTER_STATUS.writing &&
                            <Button disabled={editing}>{editing ? 'Saving' : 'Save'}</Button>
                        }
                        {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} />}

                    </form>
                    {
                        showScModal && (
                            <Modal closeModal={() => setShowScModal(false)}>
                                <p className="u-m-base-2">Starcasting is the name we give to our peer review system. You have full control over which entries will be included (students will not know if you chose to exclude their work).</p>
                                <p>Students are shown a single anonymised entry at a time and asked to give the piece a star rating based on how well they liked it. Students can review multiple pieces. The class winner is the entry with the highest average star rating.</p>
                            </Modal>
                        )
                    }
                </div>
            </div>

        </>
    )

}

export default TaskSettings