import React, { useState } from "react";
import Unit from "./Unit";
import { useQuery } from "@apollo/react-hooks";
import { MATH_CURRICULUM } from "./services/graphql";
import { Loader } from "@axeedge/go-teacher-components";
import NewProject from "../Project/NewProject";

const Courses = ({ classId, classProjects, viewTopic, setViewTopic, curriculum }) => {

    const [addItemType, setAddItemType] = useState(null);


    const getUnits = (courses) => {
        let units = [];
        courses.forEach(course => {
            course.units.forEach(u => {
                units.push(u)
            });
        });
        return units;
    }


    const { data, loading, error } = useQuery(MATH_CURRICULUM, {
        variables: {
            mathCurriculumId: curriculum.id
        }
    });

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.mathCurriculum) {

        const { mathCurriculum } = data;

        if (viewTopic) {
            return (
                <NewProject
                    classPreview={true}
                    topicId={viewTopic.id}
                    curriculum={mathCurriculum}
                    classId={classId}
                    close={() => setViewTopic(false)}
                    addItemType={addItemType}
                    setAddItemType={setAddItemType}
                />

            )
        }

        return (
            <div className="card-mobile">
                {getUnits(mathCurriculum.courses).map(unit => (
                    <Unit
                        setViewTopic={setViewTopic}
                        curriculum={mathCurriculum}
                        key={`c-${unit.id}`}
                        unit={unit}
                        classId={classId}
                        classProjects={classProjects}
                    />
                ))}
            </div>
        )
    }

    return null
}

export default Courses;