import React from "react";
import { Slash } from 'react-feather';

import styles from '../Polls.module.scss';
import cx from 'classnames';

const OverallSummary = ({ poll }) => {

    const total = poll.pollEntries.length;

    const getCount = reaction => {
        return poll.pollEntries.filter(el => el.pollReaction && el.pollReaction.reaction === reaction).length;
    }
    return (
        <ul>
            {poll.pollReactionSet.pollReactions.map(el => (
                <li key={el.id} className={styles.barItem}>
                    <div className={styles.barIcon}><img src={`../../../../images/poll/${el.reaction}_muted.svg`} alt='' /></div>
                    <span className={styles.barHolder}><span style={{ width: `${(getCount(el.reaction) / total) * 100}%` }} className={cx(styles.barValue, styles.barValueUp)}></span></span>
                </li>
            ))}
            <li className={styles.barItem}>
                <div className={styles.barIcon}><Slash size="22" /></div>
                <span className={styles.barHolder}><span style={{ width: `${(poll.pollEntries.filter(el => !el.pollReaction).length / poll.pollEntries.length) * 100}%` }} className={cx(styles.barValue, styles.barValueEmpty)}></span></span>
            </li>
        </ul>
    );

}

export default OverallSummary