import React from 'react';
import { Router } from '@reach/router';
import TokenCheck from './TokenCheck';
import Dashboard from '../../Dashboard';
import Class from '../../Class';
import Project from '../../Project';
import TaskHolder from '../../Project/scenes/Task/TaskHolder';
import PollHolder from '../../Project/scenes/Polls/PollHolder';

const AppRouter = () => {
    return (
        <Router>
            <TokenCheck path='/'>
                <Dashboard path='/' default />

                <Class path='/class/:classId' />
                <Project path='/project/:projectId/'>
                    <TaskHolder path=':chapterId' />
                    <PollHolder path='/poll/:pollId' />
                </Project>
            </TokenCheck>
        </Router>
    )
}

export default AppRouter;