import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { Button } from '@axeedge/go-teacher-components';
import StudentsEntriesList from './StudentEntriesList';
import TemplateSubTitle from './TemplateSubTitle';
import { UNSTART_CASTING_SETUP_MUTATION, BEGIN_STARCASTING_MUTATION } from '../../../services/graphql';
import { updateMathChapter } from '../../../services/utils';

const VotingSetup = ({ chapter }) => {

    const client = useApolloClient();

    const [goBack] = useMutation(UNSTART_CASTING_SETUP_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ unstartCastingForChapter }) => {
            if (unstartCastingForChapter.errors && unstartCastingForChapter.errors.length !== 0) {
                alert(unstartCastingForChapter.errors[0]);
                return;
            } else {
                updateMathChapter(unstartCastingForChapter, client);
            }
        }
    })

    const [startCasting] = useMutation(BEGIN_STARCASTING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ startCastingForChapter }) => {
            updateMathChapter(startCastingForChapter, client);
        }
    })

    return (
        <div className='chapter__voting-setup'>
            <p><TemplateSubTitle chapter={chapter} /> - Begin StarCasting</p>
            <h1>{chapter.template.name}</h1>

            <p className='u-m-base-3'>Students will be able to view and peer assess all submitted entries, however only those below will be able to win:</p>
            <StudentsEntriesList
                className='u-m-base-4'
                onlyEligible={true}
                status={chapter.status}
                entries={chapter.entries}
                chapter={chapter}
            />

            <div className='u-m-top-3'>
                <Button onClick={() => startCasting()} primary>Begin!</Button>
                <Button outline onClick={() => goBack()} className="u-m-left-1">Cancel Starcasting</Button>
            </div>
        </div>

    )
}

export default VotingSetup;
