import React, { Fragment, useState } from 'react';
import History from '../History';

import moment from 'moment';
import cx from 'classnames';
import styles from '../ManageVersions.module.scss';
import { ENTRY_HISTORY_TYPE } from '../../../../../../../../../services/constants';

const Histories = ({ histories, close, entryId, setRestoreDone }) => {

    const textHistories = histories.filter(h => h.historyType === ENTRY_HISTORY_TYPE.text).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));

    const [selectedHistory, setSelectedHistory] = useState(textHistories[0]);

    const historyDate = history => {
        const isToday = moment(history.createdAt).isSame(moment(), 'day');
        return moment(history.createdAt).format(`${isToday ? "[Today] @ HH:mm": "MM/DD @ HH:mm"}`)
    }

    return(
        <Fragment>
            <div className={styles.entryContainer}>
                <div className={styles.entryArea}>
                    <div className={cx('card-entry', styles.entryAreaCard)}>
                        <History
                            history={selectedHistory}
                            date={historyDate(selectedHistory)}
                            entryId={entryId}
                            isCurrentVersion={textHistories.indexOf(selectedHistory) === 0}
                            close={close}
                            setRestoreDone={setRestoreDone}
                        />
                    </div>
                </div>
                <div className={styles.entrySidebar}>
                    <ul className={styles.entryHistoryNav}>
                        {textHistories.map((history, i) => (
                            <li key={`version-${history.id}`}>
                                <button className={cx(styles.entryHistoryNavBtn, {[styles.entryHistoryNavBtnActive]: selectedHistory.id === history.id})} onClick={() => setSelectedHistory(history)}>
                                    {i === 0 ? 'Current version' : historyDate(history)}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default Histories;