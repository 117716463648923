import React, { useState, useContext } from 'react';
import { POLL_STATUS } from '../../../../services/constants';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import PollSidebar from './components/PollSidebar';
import PollSettings from './components/PollSettings';
import PrintableStudentsList from '../../../Class/scenes/Students/components/PrintableStudentsList';
import { Slash } from 'react-feather';
import { Dropdown } from '@axeedge/go-teacher-components';
import moment from 'moment';
import styles from './Polls.module.scss';

const ViewPoll = ({ poll }) => {

    const { currentUser } = useContext(AuthContext);
    const [showPrintableList, setShowPrintableList] = useState(false);
    const [editPoll, setEditPoll] = useState(false);

    if (showPrintableList) {
        return <PrintableStudentsList classId={poll.mathProject.studentsClass.id} school={currentUser.school} exitPrintMode={() => setShowPrintableList(false)} />
    }

    return (
        <>
            <div className={styles.poll}>
                <div className={styles.pollContent}>

                    {poll.status === POLL_STATUS.new || editPoll ?
                        <PollSettings isEdit={poll.status !== POLL_STATUS.new} poll={poll} close={() => setEditPoll(false)} />
                        : <div className="card">
                            <div className="card-body">
                                <div className="u-display-flex u-justify-between">
                                    <h2>{poll.question}</h2>
                                    <Dropdown>
                                        <li><button onClick={() => setEditPoll(true)}>Edit poll</button></li>
                                    </Dropdown>
                                </div>
                                <p>{moment(poll.createdAt).format('MMMM DD, YYYY')} {poll.status === POLL_STATUS.closed && <>- Poll Closed</>}</p>
                                <ul className={styles.pollResults}>
                                    {poll.pollEntries.map(entry => (
                                        <li key={entry.id} className={styles.student}>
                                            <div className={styles.studentMain}>
                                                <p>{`${entry.author.firstName} ${entry.author.lastName}`}</p>
                                                {entry.entry && (
                                                    <p className={styles.studentComment}>{entry.entry}</p>
                                                )}
                                            </div>
                                            {!entry.pollReaction ?
                                                <div className={styles.studentReaction}><Slash className='u-text-muted' /></div> :
                                                <div className={styles.studentReaction}><img src={`../../../images/poll/${entry.pollReaction.reaction}.svg`} height={24} alt='' /></div>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    }

                </div>
                <div className={styles.pollSidebar}>
                    <PollSidebar poll={poll} setShowPrintableList={setShowPrintableList} />
                </div>
            </div>
        </>
    )
}

export default ViewPoll;