import React, { useState, useEffect } from "react";
import { POLL_QUERY } from "../../services/graphql";
import { useQuery } from "@apollo/react-hooks";
import { Loader } from "@axeedge/go-teacher-components";
import { usePageVisibility } from 'react-page-visibility';
import ViewPoll from "./ViewPoll";

const PollHolder = ({ pollId }) => {
    const [pollInterval, setPollInterval] = useState(0);

    const isVisible = usePageVisibility();

    useEffect(() => {
        const startPolling = () => setPollInterval(20000)
        const stopPolling = () => setPollInterval(0)
        isVisible ? startPolling() : stopPolling()
    }, [isVisible]);

    const { data, loading, error } = useQuery(POLL_QUERY, {
        variables: {
            id: pollId
        },
        pollInterval
    });


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.poll) {
        const { poll } = data;
        return (
            <>
                <ViewPoll poll={poll} />
            </>
        )
    }
    return null

}

export default PollHolder;