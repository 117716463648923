// utils for books
import {
    MATH_PROJECT_CHAPTER_QUERY,
    GET_STUDENT_ENTRY
} from './graphql';

export const updateMathChapter = (data, client) => {
    if (data.errors && data.errors.length !== 0) {
        alert(data.errors[0]);
        return;
    } else {
        const { mathProjectChapter: chapter } = data;
        const taskQuery = client.readQuery({ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } });
        if (taskQuery) {
            client.writeQuery({
                query: MATH_PROJECT_CHAPTER_QUERY,
                variables: {
                    mathProjectChapterId: chapter.id
                },
                data: {
                    mathProjectChapter: {
                        ...chapter
                    }
                }
            });
        }
    }
}

export const updateEntry = (data, client) => {
    if (data.errors && data.errors.length !== 0) {
        alert(data.errors[0]);
        return;
    } else {
        client.writeQuery({
            query: GET_STUDENT_ENTRY,
            variables: {
                id: data.studentEntry.id
            },
            data: {
                studentEntry: data.studentEntry
            }
        })
    }
}