import { gql } from 'apollo-boost';

export const GET_CLASS_QUERY = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        students {
            id
            firstName
            lastName
            name
            token
            email
            loginCode
            expiredToken
            storeCode
        }
        mathProjects {
            id
            name
            topic {
                id
            }
            items {
                id
                chapter {
                    id
                }
                poll {
                    id
                    status
                }
            }
            chapters {
                id
                title
                status
                template {
                    id
                }
            }
            polls {
                id
                status
            }
        }
    }
}
`;
