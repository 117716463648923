import React, { useState } from "react";
import { Download, Settings } from "react-feather";
import loginImg from '../../../../../images/book/login.png';
import ImageFadeIn from "react-image-fade-in";
import { Button, Modal } from "@axeedge/go-teacher-components";
import {
    START_WRITING_MUTATION,
    END_WRITING_MUTATION,
    REOPEN_WRITING_MUTATION,
    START_CASTING_SETUP_MUTATION,
    STOP_CASTING_MUTATION,
    REOPEN_CASTING_MUTATION,
    FINISH_CHAPTER_MUTATION,
    REOPEN_COMPLETED_WRITING_MUTATION,
    AUTO_SET_WINNER_MUTATION,
    CHOOSE_WINNER_MUTATION,
    DELETE_CHAPTER_FROM_PROJECT,
    MATH_PROJECT_CHAPTER_QUERY,
} from '../../../services/graphql'
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { updateMathChapter } from "../../../services/utils";
import { navigate } from '@reach/router';

import TieBreak from './TieBreak';
import { CHAPTER_STATUS } from "@axeedge/go-shared-utils";
import cx from 'classnames';
import styles from '../../../Project.module.scss';
import { GET_CLASS_QUERY } from "../../../../../services/class/graphql";

const INITIAL_REOPEN_MSG = 'Please check and read through your work carefully and making any edits before clicking hand-in.';

const Sidebar = ({ chapter, enabled = true, setEditTask, setShowPrintableList }) => {

    const client = useApolloClient();


    const [tiebreak, setTiebreak] = useState(false);
    const [showReopenModal, setShowReopenModal] = useState(false);
    const [reopenMessage, setReopenMessage] = useState(INITIAL_REOPEN_MSG);
    const [showStartWritingModal, setShowStartWritingModal] = useState(false);


    const [confirmDelete, setConfirmDelete] = useState(false);


    const getButtonText = () => {
        switch (chapter.status) {
            case CHAPTER_STATUS.new:
                return 'Start Writing';
            case CHAPTER_STATUS.writing:
                return 'End Writing';
            case CHAPTER_STATUS.writing_closed:
                return 'Start StarCasting';
            case CHAPTER_STATUS.voting_ready:
                return 'Begin!';
            case CHAPTER_STATUS.voting:
                return 'End StarCasting';
            case CHAPTER_STATUS.voting_closed:
                return 'Announce Winner';
            default:
                return '';
        }
    }


    const [startWritingChapter, { loading: startingWriting }] = useMutation(START_WRITING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ startWritingChapter }) => {
            updateMathChapter(startWritingChapter, client);
            setShowStartWritingModal(false);
        },
    });


    const [deleteChapterFromProject, { loading: deleting }] = useMutation(DELETE_CHAPTER_FROM_PROJECT, {
        variables: {
            mathProjectChapterId: chapter.id,
            mathProjectId: chapter.project.id
        },
        onCompleted: ({ deleteProjectChapterFromProject }) => {
            if (deleteProjectChapterFromProject && deleteProjectChapterFromProject.errors && deleteProjectChapterFromProject.errors.length > 0) {
                alert(deleteProjectChapterFromProject.errors[0]);
                return
            }
            if (deleteProjectChapterFromProject && deleteProjectChapterFromProject.mathProject && deleteProjectChapterFromProject.deleted) {
                const project = deleteProjectChapterFromProject.mathProject;
                const item = project.items.length > 0 && project.items[0];
                setConfirmDelete(false);
                navigate(item.chapter ? `/project/${project.id}/${item.chapter.id}` : item.poll ? `/project/${project.id}/poll/${item.poll.id}` : `/project/${project.id}`);
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: chapter.project.studentsClass.id } }]
    })

    const [endWriting] = useMutation(END_WRITING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ closeWritingChapter }) => {
            updateMathChapter(closeWritingChapter, client);
        }
    })

    const [reopenWriting] = useMutation(REOPEN_WRITING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ reopenWritingChapter }) => {
            updateMathChapter(reopenWritingChapter, client);
            setReopenMessage(INITIAL_REOPEN_MSG);
            setShowReopenModal(false);
        }
    })

    const [startCastingSetup] = useMutation(START_CASTING_SETUP_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ setChapterStatusToCastingReady }) => {
            updateMathChapter(setChapterStatusToCastingReady, client);
        },

    })

    const [endCasting] = useMutation(STOP_CASTING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ stopCastingForChapter }) => {
            updateMathChapter(stopCastingForChapter, client);
        },
    })

    const [reopenCasting] = useMutation(REOPEN_CASTING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ reopenCastingForChapter }) => {
            updateMathChapter(reopenCastingForChapter, client);
        }
    })

    const [finishChapter] = useMutation(FINISH_CHAPTER_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ setChapterStatusToCompleted }) => {
            updateMathChapter(setChapterStatusToCompleted, client);
        }
    })

    const [reopenCompletedChapter] = useMutation(REOPEN_COMPLETED_WRITING_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ reopenCompletedWritingChapter }) => {
            updateMathChapter(reopenCompletedWritingChapter, client);
        }
    })

    const [announceWinner] = useMutation(AUTO_SET_WINNER_MUTATION, {
        variables: {
            mathProjectChapterId: chapter.id
        },
        onCompleted: ({ setChapterWinner }) => {
            if (setChapterWinner.tieBreaks && setChapterWinner.tieBreaks.length !== 0) {
                setTiebreak(setChapterWinner.tieBreaks);
                return;
            }
            if (setChapterWinner.errors && setChapterWinner.errors.length !== 0) {
                alert(setChapterWinner.errors[0]);
                return
            }
        },
        refetchQueries: [{ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } }]
    })


    const [chooseWinner] = useMutation(CHOOSE_WINNER_MUTATION, {
        onCompleted: ({ chooseChapterWinner }) => {
            if (chooseChapterWinner.errors && chooseChapterWinner.errors.length !== 0) {
                alert(chooseChapterWinner.errors[0]);
            } else {
                setTiebreak(false);
            }
        },
        refetchQueries: [{ query: MATH_PROJECT_CHAPTER_QUERY, variables: { mathProjectChapterId: chapter.id } }]

    })


    return (
        <>
            {showStartWritingModal && (
                <Modal closeModal={() => setShowStartWritingModal(false)}>
                    <h2>Are you sure?</h2>
                    <p className='u-m-base-2'>Starting the writing will make the project visible to your students.</p>
                    <Button disabled={startingWriting} onClick={() => startWritingChapter()} className='u-m-right-2'>Start Writing</Button>
                    <Button onClick={() => setShowStartWritingModal(false)} outline>Cancel</Button>
                </Modal>
            )}

            {showReopenModal && (
                <Modal closeModal={() => setShowReopenModal(false)}>
                    <h1>Reopen Writing</h1>
                    <p className='u-m-base-2'>This will reopen the writing for all students. Please add guidance to support the writing as required.</p>
                    <div className='basic-form__group'>
                        <textarea
                            className='basic-form__text-area'
                            value={reopenMessage}
                            onChange={e => {
                                if (e.target.value.length === 0) {
                                    alert('Please enter guidance before reopening writing');
                                    return;
                                }
                                setReopenMessage(e.target.value);
                            }}
                        ></textarea>
                    </div>
                    <Button
                        className='u-m-right-1'
                        onClick={() => {
                            reopenWriting(({ variables: { mathProjectChapterId: chapter.id, teacherMessage: reopenMessage } }))
                        }}
                    >
                        Reopen Writing
                    </Button>
                    <Button
                        onClick={() => setShowReopenModal(false)}
                        outline
                    >
                        Cancel
                    </Button>

                </Modal>
            )}

            {chapter.status === CHAPTER_STATUS.new &&

                <Button disabled={startingWriting} onClick={() => setShowStartWritingModal(true)}> Start Writing</Button>

            }
            {
                chapter.status === CHAPTER_STATUS.writing && (
                    <Button onClick={() => endWriting()} fullWidth>{getButtonText()}</Button>

                )
            }

            {
                chapter.status === CHAPTER_STATUS.writing_closed && chapter.starcastingEnabled && (
                    <>
                        <Button onClick={() => setShowReopenModal(true)} fullWidth outline className='u-m-base-2 u-m-right-1'>Re-open Writing</Button>
                        <Button disabled={!enabled} onClick={() => startCastingSetup()} fullWidth>{getButtonText()}</Button>
                        {!enabled && <p className='basic-form__hint u-p-top-2'>Must have at least 2 eligible entries to start StarCasting.</p>}
                    </>
                )
            }
            {
                chapter.status === CHAPTER_STATUS.writing_closed && !chapter.starcastingEnabled && (
                    <>
                        <Button onClick={() => setShowReopenModal(true)} fullWidth outline className='u-m-base-2 u-m-right-1'>Re-open Writing</Button>
                        <Button onClick={() => finishChapter()} fullWidth>Finish Task</Button>
                    </>
                )
            }
            {chapter.status === CHAPTER_STATUS.voting && <Button onClick={() => endCasting()} fullWidth>{getButtonText()}</Button>}
            {
                chapter.status === CHAPTER_STATUS.voting_closed && (
                    <>
                        <Button onClick={() => reopenCasting()} fullWidth outline className='u-m-base-2 u-m-right-1'>Re-open StarCasting</Button>
                        <Button onClick={() => announceWinner()} fullWidth>{getButtonText()}</Button>
                    </>
                )
            }

            {chapter.status === CHAPTER_STATUS.completed && !chapter.starcastingEnabled && <Button onClick={() => reopenCompletedChapter()} fullWidth>Reopen task</Button>}


            {chapter.status !== CHAPTER_STATUS.new &&
                <div className="u-m-top-3"><button className={cx("btn-reset", styles.taskSettingsBtn)} onClick={setEditTask}> <Settings /> Task Settings</button></div>
            }
            <ul className={cx(styles.resources, 'u-m-top-3 u-m-base-3')}>
                <li>
                    <button className={cx('btn-reset', styles.resourcesLink)} onClick={() => setShowPrintableList(true)}>
                        <div className={styles.resourcesImg}><ImageFadeIn src={loginImg} alt='parent letters' /></div>
                        <p>Students Logins</p>
                        <Download />
                    </button>
                </li>
            </ul>
            {chapter.status === CHAPTER_STATUS.new &&
                <button className="btn-danger u-d-block u-full-width" onClick={() => setConfirmDelete(true)}>Delete task</button>
            }

            {
                tiebreak && (
                    <Modal closeModal={() => setTiebreak(false)}>
                        <TieBreak chapter={chapter} chooseWinner={chooseWinner} entries={tiebreak} />
                    </Modal>
                )
            }

            {
                confirmDelete && (
                    <Modal closeModal={() => setConfirmDelete(false)}>
                        <div className="u-text-center">
                            <h1 className="u-m-base-2">Are you sure?</h1>
                            <Button disabled={deleting} onClick={() => deleteChapterFromProject()} className='u-m-right-2'>{deleting ? 'Saving...' : 'Yes'}</Button>
                            <Button outline onClick={() => setConfirmDelete(false)}>No</Button>
                        </div>
                    </Modal>
                )
            }
        </>
    )
}

export default Sidebar