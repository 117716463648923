import { gql } from 'apollo-boost';
import { mathProjectChapterQuery, pollQuery } from '../../../services/constants';
import { studentEntryQuery } from '../../../services/constants';

export const MATH_PROJECT_QUERY = gql`
query mathProject($mathProjectId: ID!) {
    mathProject(mathProjectId: $mathProjectId) {
        id
        name
        courseName
        curriculumName
        topic {
            id
            name
            topicCode
            words {
                id
                word
            }
        }
        studentsClass {
            id
            name
        }
        items {
            id
            chapter {
                id
                status
                template {
                    id
                    name
                    isSocial
                    orderId
                }
            }
            poll {
                id
                question
                status
            }
        }
        chapters {
            id
            status
            template {
                id
                name
                isSocial
                orderId
            }
        }
    }
}
`;

export const MATH_PROJECT_CHAPTER_QUERY = gql`
query mathProjectChapter($mathProjectChapterId: ID!) {
    mathProjectChapter(mathProjectChapterId: $mathProjectChapterId) {
        ${mathProjectChapterQuery}
    }
}
`;

export const POLL_QUERY = gql`
query poll($id: ID!) {
    poll(id: $id) {
        ${pollQuery}
    }
}
`;

export const DELETE_POLL = gql`
mutation deletePoll($pollId: ID!, $mathProjectId: ID!) {
    deletePoll(pollId: $pollId, mathProjectId: $mathProjectId) {
        deleted
        errors
        mathProject {
            id
            items {
                id
                chapter {
                    id
                }
                poll {
                    id
                }
            }
        }
    }
}
`;

export const CLOSE_POLL = gql`
mutation closePoll($pollId: ID!, $mathProjectId: ID!) {
    closePoll(pollId: $pollId, mathProjectId: $mathProjectId) {
        errors
        poll {
            ${pollQuery}
        }
    }
}
`;

export const REOPEN_POLL = gql`
mutation reopenPoll($pollId: ID!, $mathProjectId: ID!) {
    reopenPoll(pollId: $pollId, mathProjectId: $mathProjectId) {
        errors
        poll {
            ${pollQuery}
        }
    }
}
`;

export const START_POLL = gql`
mutation startPoll($pollId: ID!, $mathProjectId: ID!) {
    startPoll(pollId: $pollId, mathProjectId: $mathProjectId) {
        errors
        poll {
            ${pollQuery}
        }
    }
}
`;

export const ADD_WRITING_TASK = gql`
mutation addWritingTask($studentsClassId: ID!, $mathTemplateId: ID!, $mathTopicId: ID!) {
    addWritingTask(studentsClassId: $studentsClassId, mathTemplateId: $mathTemplateId, mathTopicId: $mathTopicId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const POLL_REACTIONS_SET = gql`
query pollReactionSets {
    pollReactionSets {
        id
        reactionSet
        pollReactions {
            id
            reaction
        }
    }
}
`;
export const CREATE_POLL = gql`
mutation createPoll($studentsClassId: ID!, $mathTopicId: ID!, $pollReactionSetId: ID!, $question: String!, $allowComments: Boolean!) {
    createPoll(studentsClassId: $studentsClassId,  mathTopicId: $mathTopicId, pollReactionSetId: $pollReactionSetId, question: $question, allowComments: $allowComments) {
        errors
        poll {
            ${pollQuery}
        }
    }
}
`;

export const EDIT_POLL = gql`
mutation editPoll($mathProjectId:ID!, $pollId: ID!, $question: String!, $allowComments: Boolean!) {
    editPoll(mathProjectId: $mathProjectId, pollId: $pollId, question: $question, allowComments: $allowComments) {
        errors
        poll {
            ${pollQuery}
        }
    }
}
`;

export const START_WRITING_MUTATION = gql`
mutation startWritingChapter($mathProjectChapterId: ID!) {
    startWritingChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const END_WRITING_MUTATION = gql`
mutation closeWritingChapter($mathProjectChapterId: ID!) {
    closeWritingChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;
export const REOPEN_WRITING_MUTATION = gql`
mutation reopenWritingChapter($mathProjectChapterId: ID!) {
    reopenWritingChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;
export const START_CASTING_SETUP_MUTATION = gql`
mutation setChapterStatusToCastingReady($mathProjectChapterId: ID!) {
    setChapterStatusToCastingReady(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const BEGIN_STARCASTING_MUTATION = gql`
mutation startCastingForChapter($mathProjectChapterId: ID!) {
    startCastingForChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;


export const UNSTART_CASTING_SETUP_MUTATION = gql`
mutation unstartCastingForChapter($mathProjectChapterId: ID!) {
    unstartCastingForChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const STOP_CASTING_MUTATION = gql`
mutation stopCastingForChapter($mathProjectChapterId: ID!) {
    stopCastingForChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const REOPEN_CASTING_MUTATION = gql`
mutation reopenCastingForChapter($mathProjectChapterId: ID!) {
    reopenCastingForChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const FINISH_CHAPTER_MUTATION = gql`
mutation setChapterStatusToCompleted($mathProjectChapterId: ID!) {
    setChapterStatusToCompleted(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const AUTO_SET_WINNER_MUTATION = gql`
mutation setChapterWinner($mathProjectChapterId: ID!) {
    setChapterWinner(mathProjectChapterId: $mathProjectChapterId) {
        chapter {
            ${mathProjectChapterQuery}
        }
        tieBreaks {
            ${studentEntryQuery}
        }
        errors
    }
}
`;
export const CHOOSE_WINNER_MUTATION = gql`
mutation chooseChapterWinner($mathProjectChapterId: ID!, $studentEntryId: ID!) {
    chooseChapterWinner(mathProjectChapterId: $mathProjectChapterId, studentEntryId: $studentEntryId) {
        chapter {
            ${mathProjectChapterQuery}
        }
        errors
    }
}
`;

export const REOPEN_COMPLETED_WRITING_MUTATION = gql`
mutation reopenCompletedWritingChapter($mathProjectChapterId: ID!) {
    reopenCompletedWritingChapter(mathProjectChapterId: $mathProjectChapterId) {
        errors
        mathProjectChapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const LEAVE_ENTRY_FEEDBACK_MUTATION = gql`
mutation feedbackForEntry($studentEntryId: ID!, $feedback: String!, $requiresRevision: Boolean!) {
    feedbackForEntry(studentEntryId: $studentEntryId, feedback: $feedback, requiresRevision: $requiresRevision) {
        studentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const ADD_WORD_TO_CHAPTER = gql`
mutation addVocabularyWordToChapter($mathProjectChapterId: ID!, $vocabularyWord: String!) {
    addVocabularyWordToChapter(mathProjectChapterId: $mathProjectChapterId, vocabularyWord: $vocabularyWord) {
        errors
        chapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const DELETE_WORD_FROM_CHAPTER = gql`
mutation deleteVocabularyWordFromChapter($mathProjectChapterId: ID!, $vocabularyWordId: ID!) {
    deleteVocabularyWordFromChapter(mathProjectChapterId: $mathProjectChapterId, vocabularyWordId: $vocabularyWordId) {
        errors
        deleted
    }
}
`;


export const RESTORE_ENTRY_VERSION_MUTATION = gql`
mutation restoreEntry($studentEntryId: ID!, $studentEntryHistoryId: ID!) {
    restoreEntry(studentEntryId: $studentEntryId, studentEntryHistoryId: $studentEntryHistoryId) {
        errors
        studentEntry {
            ${studentEntryQuery}
        }
    }
}
`;

export const GET_STUDENT_ENTRY = gql`
query studentEntry($id: ID!) {
    studentEntry(id: $id) {
        ${studentEntryQuery}
    }
}
`;

export const GET_ENTRY_HISTORIES = gql`
query studentEntry($id: ID!) {
    studentEntry(id: $id) {
        id
        histories {
            id
            text
            createdAt
            historyType
        }
    }
}
`;

export const DELETE_CHAPTER_FROM_PROJECT = gql`
mutation deleteProjectChapterFromProject($mathProjectId: ID!, $mathProjectChapterId: ID!) {
    deleteProjectChapterFromProject(mathProjectId: $mathProjectId, mathProjectChapterId: $mathProjectChapterId) {
        errors
        deleted
        mathProject {
            id
            items {
                id
                chapter {
                    id
                    status
                }
                poll {
                    id
                }
            }
        }
    }
}
`;


export const TOGGLE_ENTRY_ELLIGIBLE_TO_WIN = gql`
mutation setStudentEntryEligibleToWin($studentEntryId: ID!, $isEligibleToWin: Boolean!) {
    setStudentEntryEligibleToWin(studentEntryId: $studentEntryId, isEligibleToWin: $isEligibleToWin) {
        errors
        studentEntry {
             ${studentEntryQuery}
        }
    }
}
`;

export const TOGGLE_ENTRY_HIDDEN_TO_CASTING = gql`
mutation setStudentEntryHiddenToCasting($studentEntryId: ID!, $hiddenToCasting: Boolean!) {
    setStudentEntryHiddenToCasting(studentEntryId: $studentEntryId, hiddenToCasting: $hiddenToCasting) {
        errors
        studentEntry {
            ${studentEntryQuery}
        }
    }
}
`;

export const EDIT_MATH_PROJECT_CHAPTER = gql`
mutation editMathProjectChapter($mathProjectChapterId: ID!, $guidance: String!, $starcastingEnabled: Boolean!) {
    editMathProjectChapter(mathProjectChapterId: $mathProjectChapterId, guidance: $guidance, starcastingEnabled: $starcastingEnabled ) {
        errors
        chapter {
            ${mathProjectChapterQuery}
        }
    }
}
`;

export const MATH_TOPIC_QUERY = gql`
query mathTopic($mathTopicId: ID!) {
    mathTopic(mathTopicId: $mathTopicId) {
        id
        name
        description
        topicCode
        unit {
            id
            name
            course {
                id
                name
            }
        }
        templates {
            id
            name
            guidance
            orderId
        }
        words {
            id
            word
        }
    }
}
`;

export const MATH_SOCIAL_TEMPLATES = gql`
query mathSocialTemplates {
    mathSocialTemplates{
        id
        name
        guidance
        isSocial
        orderId
    }
}
`; 

export const RESET_VOCAB = gql`
mutation resetVocabularyWordsForChapter($mathProjectChapterId: ID!) {
    resetVocabularyWordsForChapter(mathProjectChapterId: $mathProjectChapterId) {
        chapter {
            id
            ${mathProjectChapterQuery}
        }
        errors
    }
}
`;