import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Loader } from "@axeedge/go-teacher-components";
import { MATH_PROJECT_QUERY } from "./services/graphql";
import Nav from './components/Nav';
import NewProject from "./NewProject";
import { ArrowLeft } from 'react-feather'
import { navigate } from '@reach/router';

const Project = ({ projectId, children }) => {

    const [viewTopic, setViewTopic] = useState(null);
    const [addItemType, setAddItemType] = useState(null);

    const { data, loading, error } = useQuery(MATH_PROJECT_QUERY, {
        variables: {
            mathProjectId: projectId
        }
    });

    useEffect(() => {
        if (data && data.mathProject && data.mathProject.items.length === 0) {
            setViewTopic(data.mathProject.topic);
        }
    }, [data]);


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.mathProject) {

        const { mathProject } = data;

        return (
            <>
                <div className='hidden-print page-header u-m-base-0'>
                    <div className='page-header__content'>
                        <button className='page-header__back' onClick={() => navigate(`/class/${mathProject.studentsClass.id}`)}>
                            <ArrowLeft className='page-header__back__icon' />
                        </button>
                        <div className='page-header__content__inner'>
                            <p>{mathProject.curriculumName} {mathProject.courseName}</p>
                            <h2 className='page-header__content__title'>Topic {mathProject.topic.topicCode}: {mathProject.topic.name}</h2>
                        </div>
                    </div>
                </div>
                <Nav
                    setViewTopic={setViewTopic}
                    viewTopic={viewTopic}
                    mathProject={mathProject}
                    addItemType={addItemType}
                    setAddItemType={setAddItemType}
                />

                {/* {mathProject.items.length > 0 && <Nav setViewTopic={setViewTopic} viewTopic={viewTopic} mathProject={mathProject} />} */}
                {viewTopic ?
                    <NewProject
                        topicId={viewTopic.id}
                        classId={mathProject.studentsClass.id}
                        close={() => mathProject.items.length >0 && setViewTopic(null)}
                        mathProject={mathProject}
                        curriculum={{ name: mathProject.curriculumName }}
                        addItemType={addItemType}
                        setAddItemType={setAddItemType}
                    />
                    : mathProject.items.length > 0 && children}
            </>
        )
    }
    return null;
}

export default Project;