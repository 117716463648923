import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';

import { ArrowLeft } from 'react-feather';
import Histories from './Histories';

import { GET_ENTRY_HISTORIES } from '../../../../../../services/graphql';

const ManageVersions = ({ entry, close, setRestoreDone }) => {

    const { data, error, loading } = useQuery(GET_ENTRY_HISTORIES, {
        variables: {
            id: entry.id
        },
        fetchPolicy: 'network-only'
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentEntry) {
        return(
            <Fragment>
                <h1 className='icon-title u-m-base-3'><button className='btn-reset' onClick={close}><ArrowLeft /> Back to entry</button></h1>
                <Histories
                    entryId={entry.id} 
                    histories={data.studentEntry.histories}
                    close={close}
                    setRestoreDone={setRestoreDone}
                />
            </Fragment>
        )
    }

    return null;
}

export default ManageVersions;