import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.boom_math_teacher;


export const ENTRY_HISTORY_TYPE = {
    text: 1,
    status: 2,
    feedback: 3,
    teacherEdit: 4
};

export const POLL_STATUS = {
    new: 0,
    open: 1,
    closed: 2
}

export const studentEntryQuery = `
id
eligibleToWin
feedback
hiddenToCasting
stars
status
submittedAt
teacherEdit
entry
winner
isActive
castsCount
castedCounter
chapter {
    id
    status
    project {
        id
        name

    }
    words {
        id
        word
    }
    starcastingEnabled
}
author {
    id
    displayName
    firstName
    lastName
    loginCode
    name
}
`;


export const mathProjectChapterQuery = `
    id
    title
    status
    project {
        id
        chapters {
            id
            title
        }
        studentsClass {
            id
        }
    }
    template {
        id
        guidance
        name
        isSocial
        orderId
        topic {
            id
            name
            description
            unit {
                id
                name
            }
        }
    }
    guidance
    starcastingEnabled
    entries {
        ${studentEntryQuery}
    }
    words {
        id 
        word
    }
`;


export const pollQuery = `
    id
    allowComments
    createdAt
    mathProject {
        id
        studentsClass {
            id
        }
        items {
            id
            chapter {
                id
            }
            poll {
                id
            }
        }
    }
    pollReactionSet {
        id
        reactionSet
        pollReactions {
            id
            url
            reaction
        }
    }
    pollEntries {
        id
        entry
        author {
            id
            firstName
            lastName
            loginCode
            school {
                id
                schoolCode
            }
        }
        pollReaction {
            id
            reaction
            pollReactionSet {
                id
                reactionSet
            }
        }
    }
    status
    question

`;