import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import mathLogo from '../../../../../../images/logos/boom-math.png';
import studentQrImg from '../../../../../../images/pupil_app_url.svg';
import studentQrImgBoom from '../../../../../../images/pupil_app_url_boom.svg';

import { Button, Loader } from '@axeedge/go-teacher-components';

import styles from './PrintableStudentsList.module.scss';
import { GET_CLASS_QUERY } from '../../../../../../services/class/graphql';

const PrintableStudentsList = ({ classId, school, exitPrintMode }) => {

    const hostname = window && window.location && window.location.hostname;

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: classId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass) {
        return (
            <div className={styles.studentsPrint}>
                <div className={cx(styles.noPrint, 'no-print')}>
                    <div className={styles.noPrintContent}>
                        <p className='u-m-base-2'>You can print this view to list out the Students in your class along with their login codes.</p>
                        <Button onClick={exitPrintMode}>Exit Print Mode</Button>
                    </div>
                </div>

                {
                    _.sortBy(data.studentsClass.students, ['lastName']).map(student => {
                        return (
                            <div key={`print-${student.id}`} className={cx(styles.student, styles.printSheet)}>
                                <img className={styles.studentAppLogo} src={mathLogo} alt='' />
                                <p className={styles.studentSchool}>{school.name}</p>
                                <h2 className={styles.studentName}>{student.name}</h2>

                                <p>Login to your account using the details below.</p>
                                <p className='u-m-base-3'><b>Keep these details safe and secret!</b></p>
                                <p className='u-m-base-3'>Scan the QR Code or type the web address in your browser then use the school code and your PIN code to login</p>
                                <div className={styles.studentUrl}>

                                    {hostname.includes('boom') ? (
                                        <img className={styles.studentQrImg} src={studentQrImgBoom} alt='' />
                                    ) : <img className={styles.studentQrImg} src={studentQrImg} alt='' />}

                                    <div className={styles.studentUrlBody}>
                                        <strong>{hostname.includes('boom') ? 'pupils.boomhub.app' : 'pupils.goapps.app'}</strong>
                                    </div>
                                </div>
                                <p className={styles.studentCode}>School code: <strong>{school.schoolCode}</strong></p>
                                <p className={styles.studentCode}>PIN code: <strong>{student.loginCode}</strong></p>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default PrintableStudentsList;
